<template>
  <div>
    <div class="search-container">
      <vs-row>
        <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start" style="padding: 0px">
          <div class="eligibility_count">
            <p class="eligibility_text">Visit Status</p>
            <!-- <p class="eligibility_text">{{ visits_count }}</p> -->
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col vs-w="8.9" vs-offset="0.1" class="mt-4">
          <vs-row class="my-6">
            <vs-col vs-w="1" vs-type="flex" vs-justify="flex-start" vs-align="center" class="none_padding">
              <p class="filter-heading">Filters</p>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" class="visitor-tree-drop none_padding"
              id="team">
              <vs-dropdown vs-custom-content vs-trigger-click>
                <a class="a-icon a-iconns ele-inside-text py-1" href.prevent @click="showTreeBox">
                  Team
                  <i class="material-icons">expand_more</i>
                </a>
                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                  <v-select class=" invc-input" v-model="initSelectedTeam" :options="treeDataTeam">
                  </v-select>
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" class="visitor-tree-drop none_padding"
              id="citys">
              <vs-dropdown vs-custom-content vs-trigger-click>
                <a class="a-icon a-iconns ele-inside-text py-1" href.prevent @click="showTreeBox">
                  City
                  <i class="material-icons">expand_more</i>
                </a>
                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                  <treeselect v-model="initSelectedCity" :multiple="true" :options="treeDataCity" :disabled="logged_in_user.team == 'CM' || logged_in_user.team == 'SR'"/>
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2.5" class="visitor-tree-drop none_padding"
              id="cc">
              <vs-dropdown vs-custom-content vs-trigger-click>
                <a class="a-icon a-iconns ele-inside-text py-1" href.prevent @click="showTreeBox"
                  style="text-align: left;">
                  City Classification
                  <i class="material-icons">expand_more</i>
                </a>
                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                  <treeselect v-model="initSelectedCC" :multiple="true" :options="treeDataCC" :disabled="logged_in_user.team == 'CM' || logged_in_user.team == 'SR'" />
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" class="visitor-tree-drop none_padding"
              id="spoc">
              <vs-dropdown vs-custom-content vs-trigger-click>
                <a class="a-icon a-iconns ele-inside-text py-1" href.prevent @click="showTreeBox">
                  SPOC
                  <i class="material-icons">expand_more</i>
                </a>
                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                  <treeselect :value-consists-of="'LEAF_PRIORITY'" v-model="initSelectedSpoc" :multiple="true"
                    :options="treeDataSpoc"  :disabled="logged_in_user.team == 'CM' || logged_in_user.team == 'SR'"/>
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-col>
            <!-- <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2.5" class="visitor-tree-drop none_padding"
                id="sc">
                <vs-dropdown vs-custom-content vs-trigger-click>
                  <a class="a-icon a-iconns ele-inside-text py-1" href.prevent @click="showTreeBox"
                    style="text-align: left;">
                    SPOC Classification
                    <i class="material-icons">expand_more</i>
                  </a>
                  <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                    <treeselect v-model="initSelectedSC" :multiple="true" :options="treeDataSC" />
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-col> -->

          </vs-row>
          <vs-row class="my-2">
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" vs-offset="1"
              class="visitor-tree-drop none_padding" id="source">
              <vs-dropdown vs-custom-content vs-trigger-click>
                <a class="a-icon a-iconns ele-inside-text py-1" href.prevent @click="showTreeBox">
                  Source
                  <i class="material-icons">expand_more</i>
                </a>
                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                  <treeselect v-model="initSelectedSource" :options="treeDataSource" />
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" class="visitor-tree-drop none_padding"
              id="status">
              <vs-dropdown vs-custom-content vs-trigger-click>
                <a class="a-icon a-iconns ele-inside-text py-1" href.prevent @click="showTreeBox">
                  Visit Type
                  <i class="material-icons">expand_more</i>
                </a>
                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                  <treeselect v-model="initSelectedVisitStatus" :multiple="true" :options="treeDataVisitorStatus" />
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" vs-offset="0.2"
              class="visitor-tree-drop none_padding" id="type">
              <vs-dropdown vs-custom-content vs-trigger-click>
                <a class="a-icon a-iconns ele-inside-text py-1" href.prevent @click="showTreeBox">
                  Status
                  <i class="material-icons">expand_more</i>
                </a>
                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                  <treeselect v-model="initSelectedVisitType" :multiple="true" :options="treeDataVisitorType" />
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" vs-offset="0.2"
              class="visitor-tree-drop none_padding" id="level_drop">
              <vs-dropdown vs-custom-content vs-trigger-click>
                <a class="a-icon a-iconns ele-inside-text py-1" href.prevent @click="showTreeBox">
                  M Level
                  <i class="material-icons">expand_more</i>
                </a>
                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                  <treeselect v-model="initSelectedLevels" :multiple="true" :options="treeDataLevels" />
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" vs-offset="0.2"
              class="visitor-tree-drop none_padding" id="u_level_drop">
              <vs-dropdown vs-custom-content vs-trigger-click>
                <a class="a-icon a-iconns ele-inside-text py-1" href.prevent @click="showTreeBox">
                  U Level
                  <i class="material-icons">expand_more</i>
                </a>
                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                  <treeselect v-model="init_selected_iiml_levels" :multiple="true" :options="macc_levels" />
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-col>
          </vs-row>

          <vs-row>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" class="none_padding mt-6" vs-w="1.1">
              <p class="filter-heading">Date Filter</p>
            </vs-col>
            <!-- <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" class="none_padding mt-6" vs-w="2.5">
                <div style="display: inline-grid; width: 100%; margin-top: -13px">
                  <label class="date_filter_heading">Next Call</label>
                  <date-range-picker ref="picker" :opens="'right'" :locale-data="{
                      firstDay: 1,
                      format: 'DD-MMM-YYYY',
                    }" :minDate="null" :maxDate="null" :singleDatePicker="false" :timePicker="false"
                    :timePicker24Hour="true" :showWeekNumbers="false" :showDropdowns="false" :autoApply="false"
                    v-model="nextCallDateRange" class="visitor">
                  </date-range-picker>
                </div>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" class="none_padding mt-6" vs-offset="0.2"
                vs-w="2.5">
                <div style="display: inline-grid; width: 100%; margin-top: -13px">
                  <label class="date_filter_heading">Last Call</label>
                  <date-range-picker ref="picker" :opens="'right'" :locale-data="{
                      firstDay: 1,
                      format: 'DD-MMM-YYYY',
                    }" :minDate="null" :maxDate="null" :singleDatePicker="false" :timePicker="false"
                    :timePicker24Hour="true" :showWeekNumbers="false" :showDropdowns="false" :autoApply="false"
                    v-model="lastCallDateateRange" class="visitor">
                  </date-range-picker>
                </div>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" class="none_padding mt-6" vs-offset="0.2"
                vs-w="2.5">
                <div style="display: inline-grid; width: 100%; margin-top: -13px">
                  <label class="date_filter_heading">Visit month and year</label>
                  <datepicker placeholder="MM/YY" :minimumView="'month'" :maximumView="'month'" class="visit_date_picker"
                    :format="customFormatter" v-model="dates"></datepicker>
                </div>
              </vs-col> -->
            <!-- <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" class="none_padding mt-6" vs-offset="0.2"
                vs-w="2.7">
                <div style="display: inline-grid; width: 100%; margin-top: -13px">
                  <label class="date_filter_heading">Enrollment month & year</label>
                  <datepicker placeholder="MM/YY" :minimumView="'month'" :maximumView="'month'" class="visit_date_picker"
                    :format="customFormatter" v-model="enrollment_dates"></datepicker>
                </div>
              </vs-col> -->
            <!-- <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="2.9"
                >
                  <div>
                    <label class="labell" style="margin-top:2px;">Next Call </label>
                    <date-range-picker
                      ref="picker"
                      :opens="'right'"
                      :locale-data="{
                        firstDay: 1,
                        format: 'DD-MMM-YYYY',
                      }"
                      :minDate="null"
                      :maxDate="null"
                      :singleDatePicker="false"
                      :timePicker="false"
                      :timePicker24Hour="true"
                      :showWeekNumbers="false"
                      :showDropdowns="false"
                      :autoApply="false"
                      v-model="nextCallDateRange"
                      style="width: 187px;display:block;"
                      class="daterangee"
                    >
                    </date-range-picker>
                  </div>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="2.9"
                >
                  <div>
                    <label class="labell" style="margin-top:2px;">Last Call </label>
                    <date-range-picker
                      ref="picker"
                      :opens="'center'"
                      :locale-data="{
                        firstDay: 1,
                        format: 'DD-MMM-YYYY',
                      }"
                      :minDate="null"
                      :maxDate="null"
                      :singleDatePicker="false"
                      :timePicker="false"
                      :timePicker24Hour="true"
                      :showWeekNumbers="false"
                      :showDropdowns="false"
                      :autoApply="false"
                      placeholder="DD/MM/YY"
                      v-model="lastCallDateateRange"
                      style="width: 187px;display:block;"
                      class="daterangee"
                    >
                    </date-range-picker>
                  </div>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="2.9"
                >
                  <div>
                    <label class="labell">Visit month and year</label>
                    <datepicker
                      placeholder="MM/YY"
                      :minimumView="'month'"
                      :maximumView="'month'"
                      class="my_date_picker"
                      :format="customFormatter"
                      v-model="dates"
                      style="width:187px;"
                    ></datepicker>
                  </div>
                </vs-col> -->
            <!-- </vs-row>
            <vs-row> -->
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" class="none_padding mt-6" vs-w="2.5">
              <div style="display: inline-grid; width: 100%; margin-top: -13px">
                <label class="date_filter_heading">Date Range</label>
                <date-range-picker ref="picker" :opens="'right'" :locale-data="{
                  firstDay: 1,
                  format: 'DD-MMM-YYYY',
                }" :minDate="null" :maxDate="null" :singleDatePicker="false" :timePicker="false"
                  :timePicker24Hour="true" :showWeekNumbers="false" :showDropdowns="false" :autoApply="false"
                  v-model="entrollmentDateRange" class="visitor">
                </date-range-picker>
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="0.7" style="margin-top: 15px">
              <div>
                <vs-button color="#0044ba" icon="clear" size="small" @click="clearDateFilter"></vs-button>
              </div>
            </vs-col>
          </vs-row>
          <vs-row class=" mt-6 mb-2">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" class="none_padding" vs-w="12">

              <div class="visitor_search_criteria mt-3">
                <h6 class="Criteria_heading ml-2 mt-2">Search Criteria:</h6>
                <vs-row vs-w="12" class="mt-2">
                  <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-offset="0.1" vs-w="10.7">
                    <div style="display: inline-block; width: 100%;">
                      <span v-for="chip in teamData" :key="chip">
                        <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                          @click="removechip(chip, 'team')" color="#caecc5">
                          <b>{{ chip }}</b>
                        </vs-chip>
                      </span>
                      <span v-for="chip in cityData" :key="chip">
                        <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                          @click="removechip(chip, 'city')" color="#EAE5FA">
                          <b>{{ chip }}</b>
                        </vs-chip>
                      </span>
                      <span v-for="chip in selectedCCData" :key="chip">
                        <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable @click="removechip(chip, 'cc')"
                          color="#FAE1FE">
                          <b>{{ chip }}</b>
                        </vs-chip>
                      </span>
                      <span v-for="chip in selectedSpocData" :key="chip">
                        <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                          @click="removechip(chip, 'spoc')" color="#BCEBFA">
                          <b>{{ chip }}</b>
                        </vs-chip>
                      </span>
                      <!-- <span v-for="chip in selectedSCData" :key="chip">
                          <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable @click="removechip(chip, 'sc')"
                            color="#B4EDFF">
                            <b>{{ chip }}</b>
                          </vs-chip>
                        </span> -->
                      <span v-if="selectedSourceData != null">
                        <vs-chip class="criteria_chip" closable
                          @click="removechip(selectedSourceData.toString(), 'source')" color="#FFC2C2">
                          <b v-if="selectedSourceData.toString() == 'mf'">By SPOC</b>
                          <b v-else-if="selectedSourceData.toString() == 'miles'">System</b>
                          <b v-else-if="selectedSourceData.toString() == 'direct_walk_in'">Direct walk-in</b>
                        </vs-chip>
                      </span>
                      <span v-for="chip in visitorStatusData" :key="chip">
                        <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                          @click="removechip(chip, 'visitorStatus')" color="#f5e1ce">
                          <b>{{ chip }}</b>
                        </vs-chip>
                      </span>
                      <span v-for="chip in visitorTypeData" :key="chip">
                        <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                          @click="removechip(chip, 'visitorType')" color="#F1A964">
                          <b>{{ chip }}</b>
                        </vs-chip>
                      </span>
                      <span v-for="chip in selectedLevels" :key="chip">
                        <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                          @click="removechip(chip, 'level')" color="#99eee3">
                          <b>{{ chip }}</b>
                        </vs-chip>
                      </span>
                      <span v-for="chip in selectedMAccLevels" :key="chip">
                        <vs-chip v-if="chip !== 'divider'" @click="removechip(chip, 'iiml_level')" class="criteria_chip"
                          closable color="#edebf3">
                          <b v-if="chip == 'blank'">No U-Level</b>
                          <b v-else>{{ chip }}</b>
                        </vs-chip>
                      </span>
                    </div>
                  </vs-col>
                  <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-start" vs-w="1.2">
                    <vs-button color="#0044ba" icon="search" @click="getVisitStatsData(1)" size="small"></vs-button>
                    &nbsp;
                    <vs-button color="#0044ba" icon="refresh" @click="refreshVisitorData" size="small"></vs-button>
                  </vs-col>
                </vs-row>
              </div>
            </vs-col>
          </vs-row>
        </vs-col>
        <vs-col vs-w="3" vs-type="flex" vs-justify="center" vs-align="flex-start" style="margin-top: -1.5%">
          <vx-card class="visit-options_filter">
            <p class="visit-option-side mb-4">Options</p>
            <vs-row class="mb-4">
              <vs-col vs-type="flex" vs-justify="center" class="mb-2 no-padding">
                <vs-input placeholder="Can-ID" v-model="identity" class="visit-input-filter" />
                <vs-button style="position: absolute; right: 9%; color: #0044ba" color="#FFFFFF" type="gradient"
                  @click="getVisitStatsData(1)" icon="search"></vs-button>
              </vs-col>
            </vs-row>
            <!-- <vs-row class="mb-4">
              <vs-col vs-type="flex" vs-justify="center" class="mb-2 no-padding">
                <vs-input placeholder="Candidate Name" v-model="candidate_name" class="visit-input-filter" />
                <vs-button style="position: absolute; right: 9%; color: #0044ba" color="#FFFFFF" type="gradient"
                  icon="search" @click="getVisitStatsData(1)"></vs-button>
              </vs-col>
            </vs-row> -->
            <!-- <vs-row class="mb-4">
                <vs-col vs-type="flex" vs-justify="center" class="mb-2 no-padding">
                  <vs-input placeholder="Company" v-model="company" class="visit-input-filter" />
                  <vs-button style="position: absolute; right: 9%; color: #0044ba" color="#FFFFFF" type="gradient"
                    icon="search" @click="getVisitStatsData(1)"></vs-button>
                </vs-col>
              </vs-row> -->
            <vs-row class="mb-4">
              <vs-col vs-type="flex" vs-justify="center" class="mb-2 no-padding">
                <vs-input placeholder="Contact Number" v-model="candidate_number" class="visit-input-filter" />
                <vs-button style="position: absolute; right: 9%; color: #0044ba" color="#FFFFFF" type="gradient"
                  icon="search" @click="getVisitStatsData(1)"></vs-button>
              </vs-col>
            </vs-row>
            <vs-row class="mb-3">
              <vs-col vs-type="flex" vs-justify="center" class="mb-2 no-padding">
                <vs-input placeholder="Email Address" v-model="email" class="visit-input-filter" />
                <vs-button style="position: absolute; right: 9%; color: #0044ba" color="#FFFFFF" type="gradient"
                  icon="search" @click="getVisitStatsData(1)"></vs-button>
              </vs-col>
            </vs-row>
            <vs-row>
              <vs-col vs-type="flex" vs-justify="center" class="mb-1">
                <vs-button color="#0044BA" icon="search" size="small" @click="getVisitStatsData(1)">Search</vs-button>
              </vs-col>
            </vs-row>
          </vx-card>
        </vs-col>
      </vs-row>
    </div>


    <!-- Visit Scheduled Stats -->
    <vx-card class="gm-stats-filter-card" style="margin:2% 0 2% 0;padding-bottom:4%">
      <vs-row vs-w="12">
        <vs-col vs-w="12" class="no-padd">
          <vs-row>
            <vs-col vs-w="12" vs-type="flex" vs-justify="center" class="desc-border-box2 no-padd mb-8">
              <p style="text-align:center">
                Visit Scheduled Stats
              </p>
            </vs-col>
            <vs-col vs-w="12" vs-type="center" vs-justify="center">
              <div class="fixTableHead">
                <table class="rwd-table" id="levels-stats">
                  <thead>
                    <tr>
                      <th v-if="logged_in_user.team == 'Admin'">GM</th>
                      <th v-else>SPOC</th>
                      <th>City Classification</th>
                      <th v-for="visit_scheduled in visit_scheduled_stats_header"
                        :key="generateKey(visit_scheduled.value)">
                        {{ visit_scheduled.lable }}
                      </th>
                    </tr>
                  </thead>
                  <tbody class="tbl-content">
                    <tr v-for="item in visit_scheduled_stats_data" :key="generateKey(item.gm_id)">
                      <td id="stats_total" v-if="logged_in_user.team == 'Admin'" @click="getGmData(item, 'visit_scheduled_stats_gm')">{{ item.gm_name }}</td>
                      <td v-else>{{ item.spoc_name }}</td>
                      <td>{{ item.city_classification }}</td>
                      <td id="stats_total" v-for="t_col in visit_scheduled_stats_header"
                        :key="generateKey(item[t_col.value])"
                        @click="getVisitScheduleTabulatorDeatils(item, t_col.value, 'visit_scheduled_stats', 1)">
                        {{ item[t_col.value] }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr v-if="visit_scheduled_stats_data.length != 0">
                      <td>Total</td>
                      <td></td>
                      <td v-for="(val, key) in total_of_visit_scheduled_stats" :key="generateKey(key)">{{ val }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </vs-col>
          </vs-row>
        </vs-col>
      </vs-row>
    </vx-card>
    <!-- Visited Stats -->
    <vx-card class="gm-stats-filter-card" style="margin:2% 0 2% 0;padding-bottom:4%">
      <vs-row vs-w="12">
        <vs-col vs-w="12" class="no-padd">
          <vs-row>
            <vs-col vs-w="12" vs-type="flex" vs-justify="center" class="desc-border-box2 no-padd mb-8">
              <p style="text-align:center">
                Visited Stats
              </p>
            </vs-col>
            <vs-col vs-w="12" vs-type="center" vs-justify="center">
              <div class="fixTableHead">
                <table class="rwd-table" id="levels-stats">
                  <thead>
                    <tr>
                      <th v-if="logged_in_user.team == 'Admin'">GM</th>
                      <th v-else>SPOC</th>
                      <th>City Classification</th>
                      <th v-for="visit_scheduled in visited_stats_header" :key="generateKey(visit_scheduled.value)">
                        {{ visit_scheduled.lable }}
                      </th>
                    </tr>
                  </thead>
                  <tbody class="tbl-content">
                    <tr v-for="item in visited_stats_data" :key="generateKey(item.gm_id)">
                      <td id="stats_total" v-if="logged_in_user.team == 'Admin'" @click="getGmData(item, 'visited_stats_gm')">{{ item.gm_name }}</td>
                      <td v-else>{{ item.spoc_name }}</td>
                      <td>{{ item.city_classification }}</td>
                      <td id="stats_total" v-for="t_col in visited_stats_header" :key="generateKey(item[t_col.value])"
                        @click="getVisitedStatsTabulatorDeatils(item, t_col.value, 'visited_stats', 1)">
                        {{ item[t_col.value] }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr v-if="visited_stats_data.length != 0">
                      <td>Total</td>
                      <td></td>
                      <td v-for="(val, key) in total_of_visited_stats" :key="generateKey(key)">{{ val }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </vs-col>
          </vs-row>
        </vs-col>
      </vs-row>
    </vx-card>

    <!-- GM Clicked Visit Scheduled Stats -->
    <vx-card v-if="gm_visit_scheduled" class="gm-stats-filter-card" style="margin:2% 0 2% 0;padding-bottom:4%">
      <vs-row vs-w="12">
        <vs-col vs-w="12" class="no-padd">
          <vs-row>
            <vs-col vs-w="12" vs-type="flex" vs-justify="center" class="desc-border-box2 no-padd mb-8">
              <p style="text-align:center; width: 30%;">
                GM - {{ gm_name }}
              </p>
            </vs-col>
            <vs-col vs-w="12" vs-type="center" vs-justify="center">
              <div class="fixTableHead">
                <table class="rwd-table" id="levels-stats">
                  <thead>
                    <tr>
                      <th>SPOC</th>
                      <th style="z-index: 1; text-align: center;" v-for="visit_scheduled in gm_visit_scheduled_stats_header"
                        :key="generateKey(visit_scheduled.value)">
                        {{ visit_scheduled.lable }}
                      </th>
                    </tr>
                  </thead>
                  <tbody class="tbl-content">
                    <tr v-for="item in gm_visit_scheduled_stats_data" :key="generateKey(item.spoc_id)">
                      <td>{{ item.spoc_name }}</td>
                      <td id="stats_total" v-for="t_col in gm_visit_scheduled_stats_header"
                        :key="generateKey(item[t_col.value])"
                        @click="getTabulatorDeatilsForGmVisitSchedules(item, t_col.value, 'gm_visit_scheduled_stats', 1)">
                        <!-- {{ item[t_col.value] }} -->
                        <span v-if="t_col.value != 'yet_to_call_transfer'">{{ item[t_col.value] }}</span><span v-else><v-row><vs-col vs-w="6"><vs-button @click="transferLeads(item, 'transfer_leads')" size="small" color="primary"
                        ><span class="editTxt">Transfer Leads ({{item.yet_to_call_transfer_count}})</span></vs-button></vs-col><vs-col vs-w="6">
                          <vs-button size="small" v-if="initSelectedTeam != 'SR'" color="primary" @click="transferLeads(item, 'transfer_NEs')"
                        ><span class="editTxt">Transfer NEs ({{item.yet_to_call_ne_transfer_count}})</span></vs-button>
                      </vs-col></v-row></span>
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr v-if="gm_visit_scheduled_stats_data.length != 0">
                      <td>Total</td>
                      <td v-for="(val, key) in total_of_gm_visit_scheduled_stats" :key="generateKey(key)">{{ val }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </vs-col>
          </vs-row>
        </vs-col>
      </vs-row>
    </vx-card>
    <!-- GM Clicked Visited Stats -->
    <vx-card v-if="gm_visited_status" class="gm-stats-filter-card" style="margin:2% 0 2% 0;padding-bottom:4%">
      <vs-row vs-w="12">
        <vs-col vs-w="12" class="no-padd">
          <vs-row>
            <vs-col vs-w="12" vs-type="flex" vs-justify="center" class="desc-border-box2 no-padd mb-8">
              <p style="text-align:center; width: 30%;">
                GM - {{ gm_name }}
              </p>
            </vs-col>
            <vs-col vs-w="12" vs-type="center" vs-justify="center">
              <div class="fixTableHead">
                <table class="rwd-table" id="levels-stats">
                  <thead>
                    <tr>
                      <th>SPOC</th>
                      <th v-for="visit_scheduled in visited_stats_header" :key="generateKey(visit_scheduled.value)">
                        {{ visit_scheduled.lable }}
                      </th>
                    </tr>
                  </thead>
                  <tbody class="tbl-content">
                    <tr v-for="item in gm_visited_stats_data" :key="generateKey(item.gm_id)">
                      <td>{{ item.spoc_name }}</td>
                      <td id="stats_total" v-for="t_col in visited_stats_header" :key="generateKey(item[t_col.value])"
                        @click="getTabulatorDeatilsForGmVisitedStats(item, t_col.value, 'gm_visited_stats', 1)">
                        {{ item[t_col.value] }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr v-if="gm_visited_stats_data.length != 0">
                      <td>Total</td>
                      <td v-for="(val, key) in total_of_gm_visited_stats" :key="generateKey(key)">{{ val }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </vs-col>
          </vs-row>
        </vs-col>
      </vs-row>
    </vx-card>

    <div class="mt-3" v-if="visit_stats_table_details.length != 0">
      <visit-status-tabulator :visit_stats_table_details="visit_stats_table_details" :selected_values="selected_filters" :logged_in_user="logged_in_user.team"/>
      <div v-if="visit_stats_table_details.length == 0" style="color: grey; margin: 5px">
        <center>NO DATA AVAILABLE</center>
      </div>
    </div>
    <vs-row v-if="visit_stats_table_details.length != 0" style="margin-top: 3%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="12">
          <vs-chip color="primary">
          <b>{{ totalCount }}</b>
          </vs-chip>
          <vs-pagination :total="tablelinks" v-model="currentTablePage"></vs-pagination>
        </vs-col>
    </vs-row>

    <vs-popup class="assignspocpopupmultiplewidthnet" :active.sync="mhpnetEnquiriesPopup" :title="mhpeditspoctitle">
      <div>
        <br/>
        <vs-row>
          <vs-col  vs-w="2" vs-type="flex" vs-justify="flex-start" vs-align="center"><b>City :</b></vs-col>
          <vs-col  vs-w="8" vs-justify="center" vs-align="center">
            <v-select
              style="width:23%; position: fixed; z-index:1100"
              v-model="selected_spoc_city"
              :options="spoc_cities"
              placeholder="Cities"
            ></v-select>
          </vs-col>
        </vs-row><br/>
        <vs-row style="margin-top: 1%">
          <vs-col  vs-w="2" vs-type="flex" vs-justify="flex-start" vs-align="center"><b>User Tag :</b></vs-col>
          <vs-col  vs-w="8" vs-justify="center" vs-align="center">
            <v-select
              style="width:23%; position: fixed; z-index:1000"
              v-model="selected_spoc_user_tag"
              :options="spoc_user_tags"
              placeholder="User Tags"
            ></v-select>
          </vs-col>
        </vs-row>
        <br/>
        <vs-row style="margin-top: 1%">
          <vs-col vs-w="2" vs-type="flex" vs-justify="center" vs-align="center">
            <vs-input
              class="inputx"
              placeholder="Count"
              v-model="mhpeditNetEnquiriecount"
              style="text-align:center"
            />
          </vs-col>
          <vs-col vs-w="8"  vs-justify="center" vs-align="center">
            <div>
              <treeselect openDirection='bottom' :multiple="true"  style="width:23%;position:fixed; z-index:900"   :options="selected_usertag_spocs_tree_data" placeholder="Select Spoc" v-model="mhpselectedColoumnSpoc" />
            </div>
          </vs-col>
          <vs-col vs-w="2" vs-justify="center" vs-align="center">
            <vs-button color="success" @click="transferMHPNetEnquiries()" type="filled" style="display:flex;justify-content:center;width:120% !important"
              >Assign</vs-button
            >
          </vs-col> </vs-row
        ><br />
      </div>
    </vs-popup>
  </div>
</template>
  
<script>
import vSelect from "vue-select";
import "vue-tree-halower/dist/halower-tree.min.css";
import FocusSearch from "../components/pagesComponents/FocusSearch.vue";
import SpocSearchTree from "../components/pagesComponents/SPOCSearchTree.vue";
import Datepicker from "vuejs-datepicker";
import EventBus from "../components/eventbus.js";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { VTree, VSelectTree } from "vue-tree-halower";
import axios from "axios";
import constants from "../../constants.json";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "vue-select/dist/vue-select.css";
import moment from "moment";
import VisitStatusTabulator from "./VisitStatusTabulator.vue"
export default {
  components: {
    Datepicker,
    VTree,
    VSelectTree,
    DateRangePicker,
    FocusSearch,
    SpocSearchTree,
    Treeselect,
    "v-select": vSelect,
    VisitStatusTabulator,
  },
  data() {
    return {
      mhp_selected_spocid_list: [],
      mhp_selected_spoc_firstname_list: [],
      from_spoc: "",
      from_spoc_name:"",
      selected_usertag_spocs_tree_data: [
        {
          id: "All",
          label: "All",
          children: [],
        }
      ],
      selected_usertag_spocs: [],
      selectedColoumnSpoc: [],
      mhpselectedColoumnSpoc: [],
      mhpnetEnquiriesPopup: false,
      mhpeditspoctitle: "",
      mhpeditNetEnquiriecount: "",
      selected_spoc_user_tag: "",
      selected_spoc_city: "",
      spoc_user_tags: [],
      spoc_cities: [],
      gm_name: '',
      gm_visit_scheduled: false,
      gm_visited_status: false,
      selecteddatebool: false,
      visit_scheduled_stats_header: [
        {
          lable: 'Visit Scheduled by SPOC',
          value: 'visit_scheduled_by_spoc'
        },
        {
          lable: 'G-Meet Scheduled by SPOC',
          value: 'gmeet_scheduled_by_spoc'
        },
        {
          lable: 'Scheduled by System',
          value: 'scheduled_by_clevertap'
        },
        {
          lable: 'Total Scheduled',
          value: 'total_scheduled'
        },
        {
          lable: 'Called',
          value: 'called'
        },
        {
          lable: 'Yet To Call',
          value: 'yet_to_call'
        }
      ],
      gm_visit_scheduled_stats_header: [
        {
          lable: 'Visit Scheduled by SPOC',
          value: 'visit_scheduled_by_spoc'
        },
        {
          lable: 'G-Meet Scheduled by SPOC',
          value: 'gmeet_scheduled_by_spoc'
        },
        {
          lable: 'Scheduled by Clevertap',
          value: 'scheduled_by_clevertap'
        },
        {
          lable: 'Total Scheduled',
          value: 'total_scheduled'
        },
        {
          lable: 'Called',
          value: 'called'
        },
        {
          lable: 'Yet To Call',
          value: 'yet_to_call'
        },
        {
          lable: 'Yet To Call Transfer',
          value: 'yet_to_call_transfer'
        }
      ],
      visited_stats_header: [
        {
          lable: 'G-Meet Done',
          value: 'gmeet_done'
        },
        {
          lable: 'Office Visit Done',
          value: 'physical_visit_done'
        },
        {
          lable: 'Total Attended',
          value: 'total_attended'
        },
      ],
      visit_scheduled_stats_data: [],
      gm_visit_scheduled_stats_data: [],
      visited_stats_data: [],
      gm_visited_stats_data: [],
      
      lang: {
        placeholder: {
          date: "Next Contact",
          dateRange: "Select a date range",
        },
      },
      selected_filters: '',
      visits_count: 0,
      table_data_showing: false,
      identity: "",
      email: "",
      candidate_number: "",
      company: "",
      candidate_name: "",
      dateRange: {
        startDate: "",
        endDate: "",
      },
      visit_stats_table_details: [],
      lastCallDateateRange: {
        startDate: null,
        endDate: null,
      },
      nextCallDateRange: {
        startDate: null,
        endDate: null,
      },
      treeDataCity: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      treeDataTeam: [],
      selectedLevels: [],
      initSelectedCity: [],
      initSelectedTeam: 'CM',
      visitStatus: "",
      visitStatusOptions: ["First", "Repeat", "Enrollment"],
      nameAutocomplete: [],
      initSelected: "",
      initSelectedBDE: [],
      initSelectedLevels: [],
      visitorStatusData: [],
      visitorTypeData: [],
      dates: null,
      enrollment_dates: null,
      treeDataBDE: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      countsleads: 0,
      treeDataSource: [
            {
              id: "mf",
              label: "By SPOC",
            },
            {
              id: "miles",
              label: "System",
            },
            {
              id: "direct_walk_in",
              label: "Direct walk-in",
            },
      ],
      treeDataLevels: [
        {
          id: "All",
          label: "All",
          children: [
            {
              id: "P1",
              label: "P1",
              children: [
                {
                  id: "M3",
                  label: "M3",
                },
                {
                  id: "M3+",
                  label: "M3+",
                },
                {
                  id: "M3++",
                  label: "M3++",
                },
                {
                  id: "M5",
                  label: "M5",
                },
                {
                  id: "M6",
                  label: "M6",
                },
                {
                  id: "M6 AR",
                  label: "M6 AR",
                },
                {
                  id: "M6 AR-",
                  label: "M6 AR-",
                },
              ],
            },
            {
              id: "P2",
              label: "P2",
              children: [
                {
                  id: "M4",
                  label: "M4",
                },
                {
                  id: "M2",
                  label: "M2",
                },
                // {
                //   id: "L2",
                //   label: "L2",
                // },
                // {
                //   id: "L3",
                //   label: "L3",
                // },
                // {
                //   id: "L4",
                //   label: "L4",
                // },
                // {
                //   id: "L6",
                //   label: "L6",
                // },
                // {
                //   id: "L5",
                //   label: "L5",
                // },
                // {
                //   id: "L3+",
                //   label: "L3+",
                // },
              ],
            },
            {
              id: "P3",
              label: "P3",
              children: [
                {
                  id: "M4-",
                  label: "M4-",
                },
                {
                  id: "M1",
                  label: "M1",
                },
                // {
                //   id: "L4-",
                //   label: "L4-",
                // },
                // {
                //   id: "L1",
                //   label: "L1",
                // },
              ],
            },
            {
              id: "ENROLLED",
              label: "ENROLLED",
              children: [
                {
                    id: "M7",
                    label: "M7",
                },
                {
                    id: "M7-",
                    label: "M7-",
                },
                {
                    id: "M7+",
                    label: "M7+",
                },
                {
                    id: "M7#",
                    label: "M7#",
                },
                {
                    id: "M7-JJ",
                    label: "M7-JJ",
                },
                {
                    id: "M7X",
                    label: "M7X",
                },
                {
                    id: "M7D1",
                    label: "M7D1",
                },
                {
                    id: "M7D2",
                    label: "M7D2",
                },
                {
                    id: "M8#1",
                    label: "M8#1"
                },
                {
                    id: "M8#1-",
                    label: "M8#1-"
                },
                {
                    id: "M8#1+",
                    label: "M8#1+"
                },
                {
                    id: "M8#2",
                    label: "M8#2"
                },
                {
                    id: "M8",
                    label: "M8"
                },
                {
                    id: "M8-",
                    label: "M8-",
                },
                {
                    id: "M8+",
                    label: "M8+"
                },
                {
                    id: "M8+1",
                    label: "M8+1"
                },
                {
                    id: "M8+2",
                    label: "M8+2"
                },
                {
                    id: "M8+3",
                    label: "M8+3"
                },
                {
                    id: "M8+4",
                    label: "M8+4"
                },
                {
                    id: "M8+5",
                    label: "M8+5"
                },
                {
                    id: "M8+6",
                    label: "M8+6"
                },
                {
                    id: "M9",
                    label: "M9",
                },
                {
                    id: "M9+1",
                    label: "M9+1",
                },
                {
                    id: "M9+2",
                    label: "M9+2",
                },
                {
                    id: "M9+3",
                    label: "M9+3",
                },
                {
                    id: "M9-",
                    label: "M9-",
                },
                {
                    id: "M10",
                    label: "M10",
                },
                {
                    id: "M10#",
                    label: "M10#",
                },
                {
                    id: "M11",
                    label: "M11",
                },
                {
                    id: "M11#",
                    label: "M11#",
                },
                {
                    id: "M12",
                    label: "M12",
                },
                {
                    id: "M7-IR",
                    label: "M7-IR",
                },
                {
                    id: "M7-INT",
                    label: "M7-INT",
                }
              ],
            },
            {
              id: "Dnd",
              label: "Dnd",
              children: [
                {
                  id: "N/A",
                  label: "N/A",
                },
                {
                  id: "DND",
                  label: "DND",
                },
              ],
            },
          ],
        },
      ],
      macc_levels: [
        {
          id: "All",
          label: "All",
          children: [
            { id: "blank", label: "No U Level" },
            { id: "U0--", label: "U0--" },
            { id: "U0", label: "U0" },
            { id: "U1", label: "U1" },
            { id: "U1+", label: "U1+" },
            { id: "U2", label: "U2" },
            { id: "U3-", label: "U3-" },
            { id: "U3", label: "U3" },
            { id: "U3+", label: "U3+" },
            { id: "U3++", label: "U3++" },
            { id: "U4", label: "U4" },
            { id: "U5", label: "U5" },
            { id: "U6", label: "U6" },
            { id: "U7-", label: "U7-" },
            { id: "U7", label: "U7" },
            { id: "U7R", label: "U7R" },
            { id: "U7+", label: "U7+" },
            { id: "U8", label: "U8" },
            { id: "U8+", label: "U8+" },
            { id: "U9-", label: "U9-" },
            { id: "U9", label: "U9" },
            { id: "U9+", label: "U9+" },
            { id: "U10-", label: "U10-" },
            { id: "U10", label: "U10" },
            { id: "U11", label: "U11" },
            { id: "U11+", label: "U11+" },
            { id: "U12", label: "U12" },
          ]
        }
      ],
      tablelinks: 0,
      totalCount: 0,
      currentTablePage: 1,
      value1: "",
      value2: "",
      value3: "",
      value4: "",
      searchIn: {
        id: "",
        name: "",
      },
      rawBde: [],
      infosearch: [],
      searchCriteria: [],
      bulkdata: [],
      count: 0,
      mwb_names: [],
      rawcities: [],
      filter_data: [],
      treeDataSC: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      initSelectedSC: [],
      selectedSpocData: [],
      selectedCCData: [],
      treeDataCC: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      initSelectedCC: [],
      initSelectedSpoc: [],
      treeDataVisitorStatus: [
        {
          id: "All",
          label: "All",
          children: [
            {
              id: "Enrollment",
              label: "Enrollment"
            },
            {
              id: "First",
              label: "First"
            },
            {
              id: "Repeat",
              label: "Repeat"
            },
          ]
        }
      ],
      initSelectedVisitStatus: [],
      treeDataSpoc: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      initSelectedVisitType: [],
      treeDataVisitorType: [
        {
          id: "All",

          label: "All",
          children: [
            {
              id: "No",
              label: "Pending",
            },
            {
              id: "Rejected",
              label: "Reject",
            },
            {
              id: "Yes",
              label: "Approved",
            },
            // {
            //   id: "Total",
            //   label: "Total",
            // },
          ]
        }
      ],
      cityData: [],
      teamData: ['CM'],
      selectedSCData: [],
      initSelectedSource: null,
      sources: [],
      selectedSourceData: null,
      filter_spoc_ids: [],
      all_gm_spocs: [],
      logged_in_user: [{ team: "", city: "", cc: "", user_tag: "", full_name: "" }],
      gm_spoc_ids: [],
      team: null,
      entrollmentDateRange: {
        // startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        startDate: new Date(),
        endDate: new Date(),
      },
      init_selected_iiml_levels: [],
      selectedMAccLevels: [],
      total_of_visit_scheduled_stats: {},
      total_of_visited_stats: {},
      total_of_gm_visit_scheduled_stats: {},
      total_of_gm_visited_stats: {},
      gm_clicked_data: '',
      clicked_stats: '',
      clicked_button_type: '',
      logged_in_user_id: "",
    };
  },
  beforeDestroy() {
    EventBus.$emit("MWBtitlehide");
  },
  mounted() {
    this.logged_in_user_id = localStorage.getItem("user_id");
    if(this.logged_in_user_id != 729 && this.logged_in_user_id != 93){
      this.gm_visit_scheduled_stats_header.pop();
    }
    // this.getAllActiveSpocsForDashboard();
    // this.getVisitStatsData(1);
    if (this.getTeam("GM")) {
      this.getGmReportingSpocs();
    } else {
      this.myprofile()
    }
    this.getAllSpocWithTeams(this.initSelectedTeam);
    // if (!this.getTeam('GM') && !this.getTeam('CM')) {
    //   this.getVisitStatsData(1)
    // }
  },
  watch: {
    selected_spoc_city(val){
        this.selected_spoc_user_tag = "";
        let selected_user_tag_spocs = [];
        for (let i = 0; i < this.iiml_spocs.length; i++) {
          const element = this.iiml_spocs[i];
          if(element.city === val){
            selected_user_tag_spocs.push(element);
          }
        }
        this.spoc_user_tags = [
          ...new Set(selected_user_tag_spocs.map((item) => item.user_tag)),
        ];
        console.log("this.spoc_user_tags",this.spoc_user_tags);
    },
    selected_spoc_user_tag(val){
        this.selectedColoumnSpoc = [];
        this.mhpselectedColoumnSpoc = [];
        this.selected_usertag_spocs = [];
        for (let i = 0; i < this.iiml_spocs.length; i++) {
          const element = this.iiml_spocs[i];
          if(element.user_tag === val && element.city == this.selected_spoc_city){
            this.selected_usertag_spocs.push(element);
          }
        }
        console.log("selected_usertag_spocs********1",this.selected_usertag_spocs);
        this.selected_usertag_spocs_tree_data = [
          {
            id: "All",
            label: "All",
            children: [],
          }
        ];
        this.selected_usertag_spocs.forEach((spoc) => {
          console.log(spoc);
          this.selected_usertag_spocs_tree_data.forEach((bde) => {
            var obj = {
                    id: spoc.id,
                    label: spoc.full_name,
                  };
              bde.children.push(obj);
            });
          });
          console.log("selected_usertag_spocs_tree_data*******2",this.selected_usertag_spocs_tree_data);
    },
    init_selected_iiml_levels(val) {
      console.log("init_selected_iiml_levels", val);
      this.mergeAllSearch();
    },
    enrollment_dates(val) {
      if (val != null) {
        this.entrollmentDateRange = {
          startDate: null,
          endDate: null,
        }
      }
    },
    entrollmentDateRange(val) {
      if (val.startDate != null && val.endDate != null) {
        this.enrollment_dates = null;
      }
    },
    initSelectedBDE: function () {
      this.mergeAllSearch();
    },
    initSelected: function () {
      this.mergeAllSearch();
    },
    initSelectedLevels: function () {
      this.mergeAllSearch();
    },
    currentTablePage: function (val) { 
      if(this.selected_filters.stats == 'visit_scheduled_stats'){
        this.getVisitScheduleTabulatorDeatils(this.selected_filters.data, this.selected_filters.val, this.selected_filters.stats, val);
      }else if(this.selected_filters.stats == 'visited_stats'){
        this.getVisitedStatsTabulatorDeatils(this.selected_filters.data, this.selected_filters.val, this.selected_filters.stats, val);
      }else if(this.selected_filters.stats == 'gm_visit_scheduled_stats'){
        this.getTabulatorDeatilsForGmVisitSchedules(this.selected_filters.data, this.selected_filters.val, this.selected_filters.stats, val);
      }else if(this.selected_filters.stats == 'gm_visited_stats'){
        this.getTabulatorDeatilsForGmVisitedStats(this.selected_filters.data, this.selected_filters.val, this.selected_filters.stats, val);
      }
    },
    infosearch: function () {
      this.mergeAllSearch();
    },
    initSelectedTeam: function (val) {
      this.getAllSpocWithTeams(val);
      this.initSelectedCity = [];
      this.initSelectedCC = [];
      this.initSelectedSpoc = [];
      this.initSelectedSC = [];
      this.cityData = [];
      this.selectedCCData = [];
      this.selectedSpocData = [];
      this.mergeAllSearch();
      if (this.logged_in_user.team != 'CM' && this.logged_in_user.team != 'GM' && this.logged_in_user.team != 'ExEd' && this.logged_in_user.team != 'SR' && val != 'others') {
        this.getSpocs(val);
      }
      this.teamData = [];
      this.teamData.push(val);
    },
    initSelectedCity: function () {
      this.mergeAllSearch();
      if (this.logged_in_user.team != 'CM' && this.logged_in_user.team != 'GM' && this.logged_in_user.team != 'ExEd' && this.logged_in_user.team != 'SR') {
        this.getOptionsData();
      }
    },
    initSelectedCC: function () {
      this.mergeAllSearch();
      if (this.logged_in_user.team != 'CM' && this.logged_in_user.team != 'GM' && this.logged_in_user.team != 'ExEd' && this.logged_in_user.team != 'SR') {
        this.getOptionsData();
      }
    },
    initSelectedSpoc: function () {
      // if(this.logged_in_user.team == 'CM' || this.logged_in_user.team == 'SR'){
      //  this.initSelectedSpoc = ['All'];
      // }else{
      // }
      if(this.logged_in_user.team != 'CM' && this.logged_in_user.team != 'SR'){
        this.mergeAllSearch();
      }
      if (this.logged_in_user.team != 'CM' && this.logged_in_user.team != 'GM' && this.logged_in_user.team != 'ExEd' && this.logged_in_user.team != 'SR') {
        this.getOptionsData();
      }
    },
    initSelectedSC: function () {
      this.mergeAllSearch();
      if (this.logged_in_user.team != 'CM' && this.logged_in_user.team != 'GM' && this.logged_in_user.team != 'ExEd' && this.logged_in_user.team != 'SR') {
        this.getOptionsData();
      }
    },
    initSelectedSource: function () {
      this.mergeAllSearch();
    },
    initSelectedVisitStatus: function () {
      this.mergeAllSearch();
    },
    initSelectedVisitType: function () {
      this.mergeAllSearch();
    },
  },
  methods: {
    transferMHPNetEnquiries(){
      // this.$vs.loading();
      var enrollment_data_from_date = null;
      var enrollment_data_to_date = null;
      if (
        this.entrollmentDateRange.startDate !== null &&
        this.entrollmentDateRange.endDate !== null
      ) {
        enrollment_data_from_date = this.customDateFormatter(this.entrollmentDateRange.startDate);
        enrollment_data_to_date = this.customDateFormatter(this.entrollmentDateRange.endDate);
      }
      let spoc_data = this.getSpocIdsAndNames(this.mhpselectedColoumnSpoc, this.selected_usertag_spocs_tree_data);
      let obj = {
        from_spoc: this.from_spoc,
        // from_spoc_name: this.from_spoc_name,
        to_spoc: spoc_data.mhp_selected_spocid_list,
        // to_spoc_name: spoc_data.mhp_selected_spoc_firstname_list,
        count: this.mhpeditNetEnquiriecount,
        start_date: enrollment_data_from_date,
        end_date: enrollment_data_to_date,
        type: this.clicked_button_type,
        team: this.teamData.join(),

      };

      console.log("MHP net enquiree transfer", obj);
      if (obj.count !== "") {
        let url = `${constants.MILES_CRM_DEV}yetToCallLeadTransfer `;
        axios
          .post(url, obj, {
            headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
          })
          .then((response) => {
            this.$vs.loading.close();
            console.log(response);
            this.handleNotification(response);
             this.mhpnetEnquiriesPopup = false;
             this.getGmData(this.gm_clicked_data, this.clicked_stats);
            // this.sortSpocs(this.selected_city);
          })
          .catch((error) => {
            this.handleError(error);
          });
      } else {
        setTimeout(() => {
          this.$vs.loading.close();
        }, 500);
        this.$vs.notify({
          title: "Require Count",
          text: "Please give count of net enquiries you transfer",
          color: "danger",
        });
      }
    },
    getSpocIdsAndNames(selected_spocs, all_spocs){
      let obj ={
       mhp_selected_spoc_firstname_list : [] ,
       mhp_selected_spocid_list : []
      }
      obj.mhp_selected_spoc_firstname_list = [];
      obj.mhp_selected_spocid_list = [];
      if(!selected_spocs.includes('All')){
        for (let i = 0; i < all_spocs[0].children.length; i++) {
          const element = all_spocs[0].children[i];
          for(let j=0; j < selected_spocs.length; j++){
            const ele = selected_spocs[j];
            if(element.id == ele){
              obj.mhp_selected_spoc_firstname_list.push(element.label);
              obj.mhp_selected_spocid_list.push(element.id)
            }
          }
        }
      }
      if(selected_spocs.includes('All')){
         for (let i = 0; i < all_spocs[0].children.length; i++) {
          const element = all_spocs[0].children[i];
              obj.mhp_selected_spoc_firstname_list.push(element.label);
              obj.mhp_selected_spocid_list.push(element.id)
        }
      }
      return obj;
    },
    transferLeads(item, type){
      console.log("transfer Leads", item, type);
      this.clicked_button_type = type;
      this.selected_spoc_user_tag = "";
      this.selected_spoc_city = "";
      this.mhpselectedColoumnSpoc = [];
      this.mhpnetEnquiriesPopup = true;
      this.mhpeditNetEnquiriecount = "";
      this.from_spoc = item.spoc_id;
      this.from_spoc_name = item.spoc_name;
       
      
      var enrollment_data_from_date = null;
      var enrollment_data_to_date = null;
      if (
        this.entrollmentDateRange.startDate !== null &&
        this.entrollmentDateRange.endDate !== null
      ) {
        enrollment_data_from_date = this.customDateFormatter(this.entrollmentDateRange.startDate);
        enrollment_data_to_date = this.customDateFormatter(this.entrollmentDateRange.endDate);
      }
      let obj = {
        start_date: enrollment_data_from_date,
        end_date: enrollment_data_to_date,
        type: type,
        spoc_id: item.spoc_id,
        team: this.teamData.join(),
      }
      this.$vs.loading();
      axios.get(`${constants.MILES_CRM_DEV}getYetToCallTranferLeadsCount`, { params: obj, headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }, })
        .then((response) => {
          console.log("getYetToCallTranferLeads", response.data);
          if(type == 'transfer_leads'){
            this.mhpeditspoctitle = "Lead Transfer - " + item.spoc_name +
            " - Total : " +
            response.data;
          }else if(type == 'transfer_NEs'){
            this.mhpeditspoctitle = "NE Transfer - " + item.spoc_name +
            " - Total : " +
            response.data;
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.handleError(error);
        });
    },
    getAllSpocWithTeams(team) {
      let url = `${constants.SERVER_API}getAllSpocWithTeams?team=${team}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("getAllSpocWithTeams", response);
          this.iiml_spocs = [];
          response.data.spocs.forEach((spoc) => {
            spoc.full_name_team = `${spoc.full_name} - ${
              spoc.user_tag ? spoc.user_tag : " "
            } - ${spoc.city}`;
            if (spoc.assign_net_enquiry === 1 || spoc.assign_net_enquiry === 0) {
              this.iiml_spocs.push(spoc);
            }
          });
          this.getCitiesAndUsertag(this.iiml_spocs);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getCitiesAndUsertag(spocs){
      this.spoc_cities = [];
      this.spoc_cities = [
        ...new Set(spocs.map((item) => item.city)),
      ];
      console.log("city filter",this.spoc_cities);
    },
    myprofile() {
      let url = `${constants.SERVER_API}getUserProfile`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          let data = response.data.data;
          this.logged_in_user.team = data.team
          this.logged_in_user.city = data.city
          this.logged_in_user.cc = data.city_classification
          this.logged_in_user.user_tag = data.user_tag
          this.logged_in_user.full_name = data.full_name
          if (this.logged_in_user.team == 'ExEd') {
            this.initSelectedTeam = 'ExEd'
            this.team = false
            this.getSpocs('ExEd');
            this.treeDataTeam = ['ExEd']
          }
          else if (this.logged_in_user.team == 'CM') {
            this.initSelectedTeam = 'CM'
            this.team = true
            this.getSpocs('CM');
            this.treeDataTeam = ['CM']
          }
          else if (this.logged_in_user.team == 'SR') {
            this.initSelectedTeam = 'SR'
            this.getSpocs('SR');
            this.treeDataTeam = ['SR']
          }
          else if (localStorage.getItem('sub_team') === 'SR' && this.logged_in_user.team == 'GM') {
            this.initSelectedTeam = 'SR'
            this.teamData = [];
            this.teamData.push('SR');
            this.getSpocs('SR');
            this.treeDataTeam = ['SR']
          }
          else if (localStorage.getItem('sub_team') === 'CM' && this.logged_in_user.team == 'GM') {
            this.initSelectedTeam = 'CM'
            this.teamData = [];
            this.teamData.push('CM');
            this.getSpocs('CM');
            this.treeDataTeam = ['CM']
          }
          else {
            this.teamData = [];
            this.teamData.push('CM');
            this.getSpocs('CM');
            this.treeDataTeam = ['CM', 'SR', 'others']
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getGmReportingSpocs() {
      let gm_id = localStorage.getItem("spoc_id");
      let ids = [];
      let url = `${constants.SERVER_API}getSpocsOfGM?gm_id=${gm_id}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          for (let i = 0; i < response.data.spocs.length; i++) {
            const element = response.data.spocs[i];
            if (!ids.includes(element.id)) {
              ids.push(element.id);
            }
          }
          this.gm_spoc_ids = response.data.spocs
          this.all_gm_spocs = ids
        })
        .catch((error) => {
          this.handleError(error);
        });
      this.myprofile()
    },
    getSpocs(val) {
      console.log(val);
      this.$vs.loading();
      let url;
      if (val == 'CM') {
        url = `https://milesdashboards.2x2.ninja/api/getSpocsBasedonCC`;
      } else if (val == 'ExEd') {
        url = `https://milesdashboards.2x2.ninja/api/getSpocsBasedonExEdTeam`;
      } else if (val == 'SR') {
        url = `https://milesdashboards.2x2.ninja/api/getSrTeamSpocs`;
      }
      axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.userAccessToken}`,
        },
      })
        .then((response) => {
          let city = localStorage.getItem("spoc_city")
          this.treeDataCity[0].children = [];
          this.treeDataSC[0].children = [];
          this.filter_data = response.data;
          const value = [...new Set(response.data.map((item) => item.city))];
          let city_options = ["Ahmedabad","Bangalore","Chennai","Delhi","Ernakulam","Hyderabad","Kolkata","Mumbai","Pune",]
          let duplicates = [];
          for (let i = 0; i < value.length; i++) {
            const element = value[i];
            const obj = {
              id: element,
              label: element,
            };
            this.cityData = [];
            if(city_options.includes(element)){
            if (this.logged_in_user.team == 'CM') {
              if (city.includes(element)) {
                this.treeDataCity[0].children.push(obj);
                this.initSelectedCity = ['All'];
                this.cityData.push(element);
              }
            }
            else if (this.logged_in_user.team == 'SR') {
              if (city.includes(element)) {
                this.treeDataCity[0].children.push(obj);
                this.initSelectedCity = ['All'];
                this.cityData.push(element);
              }
            }
            else if (this.logged_in_user.team == 'ExEd') {
              if (city.includes(element)) {
                this.treeDataCity[0].children.push(obj);
                this.initSelectedCity = ['All'];
                this.cityData.push(element);
              }
            }
            else if (this.logged_in_user.team == 'GM') {
              this.gm_spoc_ids.forEach(element1 => {
                if (element1.city == element && !duplicates.includes(element)) {
                  this.treeDataCity[0].children.push(obj);
                  this.initSelectedCity = ['All'];
                  this.cityData.push(element);
                  duplicates.push(element)
                }
              });
            } else {
              this.treeDataCC = [
                {
                  id: "All",
                  label: "All",
                  children: [],
                },
              ]
              this.treeDataCity[0].children.push(obj);
            }
            }
          }
          this.treeDataCity[0].children.sort((a, b) => a.label.localeCompare(b.label));
          const CC = [
            ...new Set(response.data.map((item) => item.city_classification)),
          ];
          this.selectedCCData = [];
          CC.forEach((item) => {
            if (item != null) {
              const obj = {
                id: item,
                label: item,
              };
              if (this.logged_in_user.team == 'CM') {
                if (this.logged_in_user.cc == item) {
                  this.treeDataCC[0].children.push(obj);
                  this.initSelectedCC = ['All'];
                  this.selectedCCData.push(item);
                }
              }
              else if (this.logged_in_user.team == 'SR') {
                if (this.logged_in_user.cc == item) {
                  this.treeDataCC[0].children.push(obj);
                  this.initSelectedCC = ['All'];
                  this.selectedCCData.push(item);
                }
              }
              else if (this.logged_in_user.team == 'ExEd') {
                if (this.logged_in_user.cc == item) {
                  this.treeDataCC[0].children.push(obj);
                  this.initSelectedCC = ['All'];
                  this.selectedCCData.push(item);
                }
              }
              else if (this.logged_in_user.team == 'GM') {
                this.gm_spoc_ids.forEach((element1) => {
                  if (element1.city_classification == item && !duplicates.includes(item)) {
                    this.treeDataCC[0].children.push(obj);
                    this.initSelectedCC = ['All'];
                    this.selectedCCData.push(item);
                    duplicates.push(item)
                  }
                });
              } else {
                this.treeDataSpoc = [
                  {
                    id: "All",
                    label: "All",
                    children: [],
                  },
                ]
                this.treeDataCC[0].children.push(obj);
              }
            }
          });
          this.treeDataCC[0].children.sort((a, b) => a.label.localeCompare(b.label));
          const spoc = [
            ...new Set(response.data.map((item) => item.spoc_name)),
          ];
          this.selectedSpocData = [];
          this.selected_default_spoc = [];
          spoc.forEach((item) => {
            if (item != null) {
              const obj = {
                id: item,
                label: item,
              };
              if (this.logged_in_user.team == 'CM') {
                if (this.logged_in_user.full_name == item) {
                  this.treeDataSpoc[0].children.push(obj);
                  this.initSelectedSpoc = ['All'];
                  this.selectedSpocData.push(item);
                  this.selected_default_spoc.push(item);
                }
              }
              else if (this.logged_in_user.team == 'SR') {
                if (this.logged_in_user.full_name == item) {
                  this.treeDataSpoc[0].children.push(obj);
                  this.initSelectedSpoc = ['All'];
                  this.selectedSpocData.push(item);
                  this.selected_default_spoc.push(item);
                }
              }
              else if (this.logged_in_user.team == 'ExEd') {
                if (this.logged_in_user.full_name == item) {
                  this.treeDataSpoc[0].children.push(obj);
                  this.initSelectedSpoc = ['All'];
                  this.selectedSpocData.push(item);
                  this.selected_default_spoc.push(item);
                }
              }
              else if (this.logged_in_user.team == 'GM') {
                this.gm_spoc_ids.forEach((element1) => {
                  if (element1.full_name == item && !duplicates.includes(item)) {
                    this.treeDataSpoc[0].children.push(obj);
                    this.initSelectedSpoc = ['All']
                    this.selectedSpocData.push(item);
                    this.selected_default_spoc.push(item);
                    duplicates.push(item)
                  }
                });
              } else {
                this.treeDataSpoc[0].children.push(obj);
              }
            }
          });
          this.treeDataSpoc[0].children.sort((a, b) => a.label.localeCompare(b.label));
          const spoc_classification = [
            ...new Set(response.data.map((item) => item.user_tag)),
          ];
          this.selectedSCData = [];
          spoc_classification.forEach((item) => {
            if (item != null) {
              const obj = {
                id: item,
                label: item,
              };
              if (this.logged_in_user.team == 'CM') {
                if (this.logged_in_user.user_tag == item) {
                  this.treeDataSC[0].children.push(obj);
                  this.initSelectedSC = ['All'];
                  this.selectedSCData.push(item)
                }
              }
              else if (this.logged_in_user.team == 'SR') {
                if (this.logged_in_user.user_tag == item) {
                  this.treeDataSC[0].children.push(obj);
                  this.initSelectedSC = ['All'];
                  this.selectedSCData.push(item)
                }
              }
              else if (this.logged_in_user.team == 'ExEd') {
                if (this.logged_in_user.user_tag == item) {
                  this.treeDataSC[0].children.push(obj);
                  this.initSelectedSC = ['All'];
                  this.selectedSCData.push(item)
                }
              }
              else if (this.logged_in_user.team == 'GM') {
                this.gm_spoc_ids.forEach(element1 => {
                  if (element1.user_tag == item && !duplicates.includes(item)) {
                    this.treeDataSC[0].children.push(obj);
                    this.initSelectedSC = ['All'];
                    duplicates.push(item)
                    this.selectedSCData.push(item);
                  }
                });
              } else { this.treeDataSC[0].children.push(obj); }
            }
          });
          this.treeDataSC[0].children.sort((a, b) => a.label.localeCompare(b.label));
          this.getVisitStatsData(1);
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.handleError(error);
        });
    },
    getOptionsData() {
      if (this.initSelectedCity.length != 0 && this.initSelectedCC.length == 0 && this.initSelectedSpoc.length == 0 && this.initSelectedSC.length == 0) {
        this.getUinqeCityData(this.filter_data);
        this.getCityWisesData();
      } else if (this.initSelectedCity.length == 0 && this.initSelectedCC.length != 0 && this.initSelectedSpoc.length == 0 && this.initSelectedSC.length == 0) {
        this.getCCData(this.filter_data);
        this.getCCWisesData();
      } else if (this.initSelectedCity.length == 0 && this.initSelectedCC.length == 0 && this.initSelectedSpoc.length != 0 && this.initSelectedSC.length == 0) {
        this.getSpocsData(this.filter_data);
        this.getSpocWisesData();
      } else if (this.initSelectedCity.length == 0 && this.initSelectedCC.length == 0 && this.initSelectedSpoc.length == 0 && this.initSelectedSC.length != 0) {
        this.getSCData(this.filter_data);
        this.getSCWisesData();
      } else if (this.initSelectedCity.length == 0 && this.initSelectedCC.length == 0 && this.initSelectedSpoc.length == 0 && this.initSelectedSC.length == 0) {
        this.getCCData(this.filter_data);
        this.getSpocsData(this.filter_data);
        this.getSCData(this.filter_data);
        this.getUinqeCityData(this.filter_data);
      }
    },
    getUinqeCityData(data) {
      this.treeDataCity[0].children = [];
      const cityData = [...new Set(data.map((item) => item.city))].sort();
      for (let i = 0; i < cityData.length; i++) {
        const element = cityData[i];
        const obj = {
          id: element,
          label: element,
        };
        this.treeDataCity[0].children.push(obj);
      }
    },
    getCCData(data) {
      this.treeDataCC[0].children = [];
      const cc_data = [...new Set(data.map((item) => item.city_classification))].sort();
      for (let i = 0; i < cc_data.length; i++) {
        const element = cc_data[i];
        if (element != null) {
          const obj = {
            id: element,
            label: element,
          };
          this.treeDataCC[0].children.push(obj);
        }
      }
    },
    getSpocsData(data) {
      this.treeDataSpoc[0].children = [];
      const spoc_data = [...new Set(data.map((item) => item.spoc_name))].sort();
      for (let i = 0; i < spoc_data.length; i++) {
        const element = spoc_data[i];
        if (element != null) {
          const obj = {
            id: element,
            label: element,
          };
          this.treeDataSpoc[0].children.push(obj);
        }
      }
    },
    getSCData(data) {
      this.treeDataSC[0].children = [];
      const sc_data = [...new Set(data.map((item) => item.user_tag))].sort();
      for (let i = 0; i < sc_data.length; i++) {
        const element = sc_data[i];
        if (element != null) {
          const obj = {
            id: element,
            label: element,
          };
          this.treeDataSC[0].children.push(obj);
        }
      }
    },
    getSpocWisesData() {
      const city_data = [];
      const CC_data = [];
      const SC_data = [];
      this.treeDataSC[0].children = [];
      this.treeDataCC[0].children = [];
      this.treeDataCity[0].children = [];
      this.filter_data.forEach((raw) => {
        this.selectedSpocData.forEach((spoc) => {
          if (raw.spoc_name == spoc) {
            city_data.push(raw.city);
          }
        });
      });
      this.filter_data.forEach((raw) => {
        this.selectedSpocData.forEach((spoc) => {
          if (raw.spoc_name == spoc) {
            CC_data.push(raw.city_classification)
          }
        });
      });
      this.filter_data.forEach((raw) => {
        this.selectedSpocData.forEach((spoc) => {
          if (raw.spoc_name == spoc) {
            SC_data.push(raw.user_tag);
          }
        })
      });
      const city = [...new Set(city_data.map((item) => item))];
      for (let index = 0; index < city.length; index++) {
        const data = city[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataCity[0].children.push(obj);
        }
      }
      const cc = [...new Set(CC_data.map((item) => item))];
      for (let index = 0; index < cc.length; index++) {
        const data = cc[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataCC[0].children.push(obj);
        }
      }
      const sc = [...new Set(SC_data.map((item) => item))];
      for (let index = 0; index < sc.length; index++) {
        const data = sc[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataSC[0].children.push(obj);
        }
      }
    },
    getCCWisesData() {
      const city_data = [];
      const spoc_data = [];
      const SC_data = [];
      this.treeDataSC[0].children = [];
      this.treeDataSpoc[0].children = [];
      this.treeDataCity[0].children = [];
      this.filter_data.forEach((raw) => {
        this.initSelectedCC.forEach((cc) => {
          if (raw.city_classification == cc) {
            city_data.push(raw.city);
          }
        });
      });
      this.filter_data.forEach((raw) => {
        this.initSelectedCC.forEach((cc) => {
          if (raw.city_classification == cc) {
            spoc_data.push(raw.spoc_name);
          }
        });
      });
      this.filter_data.forEach((raw) => {
        this.initSelectedCC.forEach((cc) => {
          if (raw.city_classification == cc) {
            SC_data.push(raw.user_tag);
          }
        });
      });
      const city = [...new Set(city_data.map((item) => item))];
      for (let index = 0; index < city.length; index++) {
        const data = city[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataCity[0].children.push(obj);
        }
      }
      const spoc = [...new Set(spoc_data.map((item) => item))];
      for (let index = 0; index < spoc.length; index++) {
        const data = spoc[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataSpoc[0].children.push(obj);
        }
      }
      const sc = [...new Set(SC_data.map((item) => item))];
      for (let index = 0; index < sc.length; index++) {
        const data = sc[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataSC[0].children.push(obj);
        }
      }
    },
    getCityWisesData() {
      const cc_data = [];
      const spoc_data = [];
      const SC_data = [];
      this.treeDataSC[0].children = [];
      this.treeDataSpoc[0].children = [];
      this.treeDataCC[0].children = [];
      this.filter_data.forEach((raw) => {
        this.cityData.forEach((city) => {
          if (raw.city == city) {
            cc_data.push(raw.city_classification);
          }
        });
      });
      this.filter_data.forEach((raw) => {
        this.cityData.forEach((city) => {
          if (raw.city == city) {
            spoc_data.push(raw.spoc_name);
          }
        });
      });
      this.filter_data.forEach((raw) => {
        this.cityData.forEach((city) => {
          if (raw.city == city) {
            SC_data.push(raw.user_tag);
          }
        });
      });
      const cc = [...new Set(cc_data.map((item) => item))];
      for (let index = 0; index < cc.length; index++) {
        const data = cc[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataCC[0].children.push(obj);
        }
      }
      const spoc = [...new Set(spoc_data.map((item) => item))];
      for (let index = 0; index < spoc.length; index++) {
        const data = spoc[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataSpoc[0].children.push(obj);
        }
      }
      const sc = [...new Set(SC_data.map((item) => item))];
      for (let index = 0; index < sc.length; index++) {
        const data = sc[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataSC[0].children.push(obj);
        }
      }
    },
    getSCWisesData() {
      const cc_data = [];
      const spoc_data = [];
      const city_data = [];
      this.treeDataCity[0].children = [];
      this.treeDataSpoc[0].children = [];
      this.treeDataCC[0].children = [];
      this.filter_data.forEach((raw) => {
        this.selectedSCData.forEach((sc) => {
          if (raw.user_tag == sc) {
            city_data.push(raw.city);
          }
        });
      });
      this.filter_data.forEach((raw) => {
        this.selectedSCData.forEach((sc) => {
          if (raw.user_tag == sc) {
            spoc_data.push(raw.spoc_name);
          }
        });
      });
      this.filter_data.forEach((raw) => {
        this.selectedSCData.forEach((sc) => {
          if (raw.user_tag == sc) {
            cc_data.push(raw.city_classification);
          }
        });
      });
      const city = [...new Set(city_data.map((item) => item))];
      for (let index = 0; index < city.length; index++) {
        const data = city[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataCity[0].children.push(obj);
        }
      }
      const cc = [...new Set(cc_data.map((item) => item))];
      for (let index = 0; index < cc.length; index++) {
        const data = cc[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataCC[0].children.push(obj);
        }
      }
      const spoc = [...new Set(spoc_data.map((item) => item))];
      for (let index = 0; index < spoc.length; index++) {
        const data = spoc[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataSpoc[0].children.push(obj);
        }
      }
    },
    customFormatter(date) {
      return moment(date).format("MMM-YYYY");
    },
    clearDateFilter() {
      this.lastCallDateateRange = {
        startDate: null,
        endDate: null,
      };
      this.nextCallDateRange = {
        startDate: null,
        endDate: null,
      };
      this.dates = null;
      this.enrollment_dates = null;
      this.entrollmentDateRange = {
        startDate: null,
        endDate: null,
      }
    },
    getAllSpocId() {
      if (this.selectedSpocData.length !== 0) {
        this.filter_spoc_ids = [];
        this.filter_data.forEach((raw) => {
          this.selectedSpocData.forEach((spoc) => {
            if (raw.spoc_name == spoc) {
              this.filter_spoc_ids.push(raw.id);
            }
          })
        });
      } else {
        let check_filters = false
        this.filter_spoc_ids = [];
        this.for_notify = "";
        console.log("selectedSCData", this.selectedSCData)
        this.filter_data.forEach((raw) => {
          if (
            this.cityData.length != 0 &&
            this.selectedCCData.length == 0 &&
            this.selectedSpocData.length !== 0 &&
            this.selectedSCData.length == 0) {
            this.cityData.forEach((city) => {
              this.selectedSpocData.forEach((spoc) => {
                if (raw.city == city && raw.full_name == spoc) {
                  this.filter_spoc_ids.push(raw.id);
                }
              });
            });
          }
          else if (
            this.cityData.length != 0 &&
            this.selectedCCData.length == 0 &&
            this.selectedSpocData.length !== 0 &&
            this.selectedSCData.length != 0) {
            this.cityData.forEach((city) => {
              this.selectedSpocData.forEach((spoc) => {
                this.selectedSCData.forEach((sc) => {
                  if (raw.user_tag == sc && raw.city == city && raw.full_name == spoc) {
                    this.filter_spoc_ids.push(raw.id);
                  }
                })
              });
            });
          }
          else if (this.cityData.length == 0 &&
            this.selectedCCData.length == 0 &&
            this.selectedSCData.length == 0 &&
            this.selectedSpocData.length == 0) {
            this.filter_spoc_ids.push(raw.id);
          }
          else if (this.cityData.length == 0 &&
            this.selectedCCData.length == 0 &&
            this.selectedSCData.length !== 0 &&
            this.selectedSpocData.length !== 0) {
            check_filters = true
            this.selectedSpocData.forEach((spoc) => {
              this.selectedSCData.forEach((sc) => {
                if (raw.user_tag == sc && raw.full_name == spoc) {
                  this.filter_spoc_ids.push(raw.id);
                }
              })
            });
          }
          else if (this.cityData.length == 0 &&
            this.selectedCCData.length == 0 &&
            this.selectedSCData.length !== 0 &&
            this.selectedSpocData.length == 0) {
            this.selectedSCData.forEach((sc) => {
              if (raw.user_tag == sc) {
                this.filter_spoc_ids.push(raw.id);
              }
            });
          }
          else if (this.cityData.length == 0 &&
            this.selectedCCData.length == 0 &&
            this.selectedSCData.length == 0 &&
            this.selectedSpocData.length !== 0) {
            this.selectedSpocData.forEach((spoc) => {
              if (raw.full_name == spoc) {
                this.filter_spoc_ids.push(raw.id);
              }
            });
          }
          else if (
            this.cityData.length != 0 &&
            this.selectedCCData.length == 0 &&
            this.selectedSCData.length == 0 &&
            this.selectedSpocData.length == 0
          ) {
            this.cityData.forEach((city) => {
              if (raw.city == city) {
                this.filter_spoc_ids.push(raw.id);
              }
            });
          } else if (
            this.cityData.length != 0 &&
            this.selectedSCData.length !== 0 &&
            this.selectedCCData.length == 0 &&
            this.selectedSpocData.length == 0
          ) {
            this.cityData.forEach((city) => {
              this.selectedSCData.forEach((sc) => {
                if (raw.city == city && raw.user_tag == sc) {
                  this.filter_spoc_ids.push(raw.id);
                  // console.log("spoc data", raw);
                } else {
                  this.for_notify = "empty";
                }
              });
            });
          } else if (
            this.cityData.length != 0 &&
            this.selectedSCData.length !== 0 &&
            this.selectedCCData.length != 0 &&
            this.selectedSpocData.length == 0
          ) {
            this.cityData.forEach((city) => {
              this.selectedSCData.forEach((sc) => {
                this.selectedCCData.forEach((cc) => {
                  if (raw.city == city && raw.user_tag == sc && raw.city_classification == cc) {
                    this.filter_spoc_ids.push(raw.id);
                  } else {
                    this.for_notify = "empty";
                  }
                })
              });
            });
          }
          else if (
            this.cityData.length != 0 &&
            this.selectedCCData.length != 0 &&
            this.selectedSpocData.length !== 0 &&
            this.selectedSCData.length == 0
          ) {
            this.cityData.forEach((city) => {
              this.selectedCCData.forEach((cc) => {
                this.selectedSpocData.forEach((spoc) => {
                  if (
                    raw.city == city &&
                    raw.city_classification == cc &&
                    raw.full_name == spoc
                  ) {
                    this.filter_spoc_ids.push(raw.id);
                  }
                });
              });
            });
          }
          else if (
            this.cityData.length != 0 &&
            this.selectedCCData.length != 0 &&
            this.selectedSpocData.length == 0 &&
            this.selectedSCData.length == 0
          ) {
            this.cityData.forEach((city) => {
              this.selectedCCData.forEach((cc) => {
                if (
                  raw.city == city &&
                  raw.city_classification == cc
                ) {
                  this.filter_spoc_ids.push(raw.id);
                }
              });
            });
          }
          else if (
            this.cityData.length != 0 &&
            this.selectedCCData.length != 0 &&
            this.selectedSpocData.length == 0 &&
            this.selectedSCData.length !== 0
          ) {
            this.cityData.forEach((city) => {
              this.selectedCCData.forEach((cc) => {
                this.selectedSCData.forEach((sc) => {
                  if (
                    raw.city == city &&
                    raw.city_classification == cc &&
                    raw.user_tag == sc
                  ) {
                    this.filter_spoc_ids.push(raw.id);
                  }
                  else {
                    this.for_notify = "empty";
                  }
                })
              });
            });
          }
          else if (
            this.cityData.length != 0 &&
            this.selectedCCData.length != 0 &&
            this.selectedSCData.length !== 0 &&
            this.selectedSpocData.length !== 0
          ) {
            this.cityData.forEach((city) => {
              this.selectedCCData.forEach((cc) => {
                this.selectedSCData.forEach((sc) => {
                  this.selectedSpocData.forEach((spoc) => {
                    if (
                      raw.city == city &&
                      raw.city_classification == cc &&
                      raw.user_tag == sc &&
                      raw.full_name == spoc
                    ) {
                      this.filter_spoc_ids.push(raw.id);
                    }
                    else {
                      this.for_notify = "empty";
                    }
                  });
                });
              });
            });
          }
          else if (
            this.selectedSCData.length !== 0
          ) {
            this.selectedSCData.forEach((sc) => {
              if (
                raw.user_tag == sc
              ) {
                this.filter_spoc_ids.push(raw.id);
              }
            });
          } else if (this.logged_in_user.team == 'GM' && this.cityData.length == 0) {
            this.filter_spoc_ids = this.all_gm_spocs
          }
        });
        if (this.filter_spoc_ids.length == 0 && check_filters) {
          this.initSelectedSpoc = []
          this.initSelectedSC = []
          this.selectedSCData = []
          this.selectedSpocData = []
          this.$vs.notify({
            text: '0 spocs found for this filters',
            color: "danger",
          });
        }
        this.filter_spoc_ids = this.filter_spoc_ids.filter((item, index) => this.filter_spoc_ids.indexOf(item) === index);
      }
    },
    async getVisitStatsData(page) {
      this.$vs.loading();
      // this.filter_spoc_ids = [];
      let logs = await this.getAllSpocId();
      console.log(logs)
      var year = null;
      var month = null;
      var enrollment_year = null;
      var enrollment_month = null;
      if (this.dates != null) {
        month = moment(this.dates).format("MM");
        year = moment(this.dates).format("YYYY")
      }
      if (this.enrollment_dates != null) {
        enrollment_month = moment(this.enrollment_dates).format("MM");
        enrollment_year = moment(this.enrollment_dates).format("YYYY")
      }
      var next_call_from = null;
      var next_call_to = null;
      if (
        this.nextCallDateRange.startDate !== null &&
        this.nextCallDateRange.endDate !== null
      ) {
        next_call_from = this.datatoTimestamp(this.nextCallDateRange.startDate);
        next_call_to = this.datatoTimestamp(this.nextCallDateRange.endDate);
      }
      var last_call_from = null;
      var last_call_to = null;
      if (
        this.lastCallDateateRange.startDate !== null &&
        this.lastCallDateateRange.endDate !== null
      ) {
        last_call_from = this.datatoTimestamp(
          this.lastCallDateateRange.startDate
        );
        last_call_to = this.datatoTimestamp(this.lastCallDateateRange.endDate);
      }
      var enrollment_data_from_date = null;
      var enrollment_data_to_date = null;
      if (
        this.entrollmentDateRange.startDate !== null &&
        this.entrollmentDateRange.endDate !== null
      ) {
        enrollment_data_from_date = this.customDateFormatter(this.entrollmentDateRange.startDate);
        enrollment_data_to_date = this.customDateFormatter(this.entrollmentDateRange.endDate);
      }
      let obj = {
        spoc_ids: null,
        team: this.teamData.join(),
        year: year,
        month: month,
        identity: this.identity,
        email: this.email,
        mobile: this.candidate_number,
        name: this.candidate_name,
        company: this.company,
        visit_type: this.visitorStatusData.join(),
        visit_status: this.visitorTypeData.join(),
        m_level: this.selectedLevels.join(),
        u_level: this.selectedMAccLevels.join(),
        enrollment_year: enrollment_year,
        enrollment_month: enrollment_month,
        last_call_from_date: last_call_from,
        last_call_to_date: last_call_to,
        next_call_from_date: next_call_from,
        next_call_to_date: next_call_to,
        start_date: enrollment_data_from_date,
        end_date: enrollment_data_to_date,
      }
      if (this.selectedSourceData != null) {
         obj['source'] = this.selectedSourceData.toString();
      }
      if (this.initSelectedCC.length != 0 || this.initSelectedCity.length != 0 || this.initSelectedSpoc.length != 0) {
        obj.spoc_ids = this.filter_spoc_ids.join()
      }
      this.visit_stats_table_details = [];
      this.visit_scheduled_stats_data = [];
      this.visited_stats_data = [];
      this.gm_visit_scheduled_stats_data = [];
      this.gm_visited_stats_data = [];
      this.gm_visit_scheduled = false;
      this.gm_visited_status = false;
      console.log("selected spoc", obj, page)
      let url = `${constants.MILES_CRM_DEV}visitScheduledStats`;
      axios.get(url, {
        params: obj,
        headers: {
          Authorization: `Bearer ${localStorage.userAccessToken}`,
        },
      })
        .then((response) => {
          this.visit_scheduled_stats_data = response.data;
          this.getScheduledVisitStatsTotals(this.visit_scheduled_stats_data);
          axios.get(`${constants.MILES_CRM_DEV}visitedStats`, { params: obj, headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }, })
            .then((response) => {
                console.log("response2", response.data);
                this.visited_stats_data = response.data;
                this.getVisitedStatsTotals(this.visited_stats_data);
                this.$vs.loading.close(); 
            })
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.handleError(error);
        });
    },
    getScheduledVisitStatsTotals(data) {
      this.total_of_visit_scheduled_stats = {}
      data.forEach(element => {
        if (!this.total_of_visit_scheduled_stats.hasOwnProperty('visit_scheduled_by_spoc') && element['visit_scheduled_by_spoc'] != undefined) {
          this.total_of_visit_scheduled_stats['visit_scheduled_by_spoc'] = element['visit_scheduled_by_spoc']
        }
        else if (element['visit_scheduled_by_spoc'] != undefined) {
          this.total_of_visit_scheduled_stats['visit_scheduled_by_spoc'] += element['visit_scheduled_by_spoc']
        }
        if (!this.total_of_visit_scheduled_stats.hasOwnProperty('gmeet_scheduled_by_spoc') && element['gmeet_scheduled_by_spoc'] != undefined) {
          this.total_of_visit_scheduled_stats['gmeet_scheduled_by_spoc'] = element['gmeet_scheduled_by_spoc']
        }
        else if (element['gmeet_scheduled_by_spoc'] != undefined) {
          this.total_of_visit_scheduled_stats['gmeet_scheduled_by_spoc'] += element['gmeet_scheduled_by_spoc']
        }
        if (!this.total_of_visit_scheduled_stats.hasOwnProperty('scheduled_by_clevertap') && element['scheduled_by_clevertap'] != undefined) {
          this.total_of_visit_scheduled_stats['scheduled_by_clevertap'] = element['scheduled_by_clevertap']
        }
        else if (element['scheduled_by_clevertap'] != undefined) {
          this.total_of_visit_scheduled_stats['scheduled_by_clevertap'] += element['scheduled_by_clevertap']
        }
        if (!this.total_of_visit_scheduled_stats.hasOwnProperty('total_scheduled') && element['total_scheduled'] != undefined) {
          this.total_of_visit_scheduled_stats['total_scheduled'] = element['total_scheduled']
        }
        else if (element['total_scheduled'] != undefined) {
          this.total_of_visit_scheduled_stats['total_scheduled'] += element['total_scheduled']
        }
        if (!this.total_of_visit_scheduled_stats.hasOwnProperty('called') && element['called'] != undefined) {
          this.total_of_visit_scheduled_stats['called'] = element['called']
        }
        else if (element['called'] != undefined) {
          this.total_of_visit_scheduled_stats['called'] += element['called']
        }
        if (!this.total_of_visit_scheduled_stats.hasOwnProperty('yet_to_call') && element['yet_to_call'] != undefined) {
          this.total_of_visit_scheduled_stats['yet_to_call'] = element['yet_to_call']
        }
        else if (element['yet_to_call'] != undefined) {
          this.total_of_visit_scheduled_stats['yet_to_call'] += element['yet_to_call']
        }


      });
      console.log("totals", this.total_of_visit_scheduled_stats);
    },
    getGmScheduledVisitStatsTotals(data) {
      this.total_of_gm_visit_scheduled_stats = {}
      data.forEach(element => {
        if (!this.total_of_gm_visit_scheduled_stats.hasOwnProperty('visit_scheduled_by_spoc') && element['visit_scheduled_by_spoc'] != undefined) {
          this.total_of_gm_visit_scheduled_stats['visit_scheduled_by_spoc'] = element['visit_scheduled_by_spoc']
        }
        else if (element['visit_scheduled_by_spoc'] != undefined) {
          this.total_of_gm_visit_scheduled_stats['visit_scheduled_by_spoc'] += element['visit_scheduled_by_spoc']
        }
        if (!this.total_of_gm_visit_scheduled_stats.hasOwnProperty('gmeet_scheduled_by_spoc') && element['gmeet_scheduled_by_spoc'] != undefined) {
          this.total_of_gm_visit_scheduled_stats['gmeet_scheduled_by_spoc'] = element['gmeet_scheduled_by_spoc']
        }
        else if (element['gmeet_scheduled_by_spoc'] != undefined) {
          this.total_of_gm_visit_scheduled_stats['gmeet_scheduled_by_spoc'] += element['gmeet_scheduled_by_spoc']
        }
        if (!this.total_of_gm_visit_scheduled_stats.hasOwnProperty('scheduled_by_clevertap') && element['scheduled_by_clevertap'] != undefined) {
          this.total_of_gm_visit_scheduled_stats['scheduled_by_clevertap'] = element['scheduled_by_clevertap']
        }
        else if (element['scheduled_by_clevertap'] != undefined) {
          this.total_of_gm_visit_scheduled_stats['scheduled_by_clevertap'] += element['scheduled_by_clevertap']
        }
        if (!this.total_of_gm_visit_scheduled_stats.hasOwnProperty('total_scheduled') && element['total_scheduled'] != undefined) {
          this.total_of_gm_visit_scheduled_stats['total_scheduled'] = element['total_scheduled']
        }
        else if (element['total_scheduled'] != undefined) {
          this.total_of_gm_visit_scheduled_stats['total_scheduled'] += element['total_scheduled']
        }
        if (!this.total_of_gm_visit_scheduled_stats.hasOwnProperty('called') && element['called'] != undefined) {
          this.total_of_gm_visit_scheduled_stats['called'] = element['called']
        }
        else if (element['called'] != undefined) {
          this.total_of_gm_visit_scheduled_stats['called'] += element['called']
        }
        if (!this.total_of_gm_visit_scheduled_stats.hasOwnProperty('yet_to_call') && element['yet_to_call'] != undefined) {
          this.total_of_gm_visit_scheduled_stats['yet_to_call'] = element['yet_to_call']
        }
        else if (element['yet_to_call'] != undefined) {
          this.total_of_gm_visit_scheduled_stats['yet_to_call'] += element['yet_to_call']
        }


      });
      console.log("totals", this.total_of_gm_visit_scheduled_stats);
    },
    getVisitedStatsTotals(data) {
      this.total_of_visited_stats = {}
      data.forEach(element => {
        if (!this.total_of_visited_stats.hasOwnProperty('gmeet_done') && element['gmeet_done'] != undefined) {
          this.total_of_visited_stats['gmeet_done'] = element['gmeet_done']
        }
        else if (element['gmeet_done'] != undefined) {
          this.total_of_visited_stats['gmeet_done'] += element['gmeet_done']
        }
        if (!this.total_of_visited_stats.hasOwnProperty('physical_visit_done') && element['physical_visit_done'] != undefined) {
          this.total_of_visited_stats['physical_visit_done'] = element['physical_visit_done']
        }
        else if (element['physical_visit_done'] != undefined) {
          this.total_of_visited_stats['physical_visit_done'] += element['physical_visit_done']
        }
        if (!this.total_of_visited_stats.hasOwnProperty('total_attended') && element['total_attended'] != undefined) {
          this.total_of_visited_stats['total_attended'] = element['total_attended']
        }
        else if (element['total_attended'] != undefined) {
          this.total_of_visited_stats['total_attended'] += element['total_attended']
        }
      });
      console.log("visited totals", this.total_of_visited_stats);
    },
    getGmVisitedStatsTotals(data) {
      this.total_of_gm_visited_stats = {}
      data.forEach(element => {
        if (!this.total_of_gm_visited_stats.hasOwnProperty('gmeet_done') && element['gmeet_done'] != undefined) {
          this.total_of_gm_visited_stats['gmeet_done'] = element['gmeet_done']
        }
        else if (element['gmeet_done'] != undefined) {
          this.total_of_gm_visited_stats['gmeet_done'] += element['gmeet_done']
        }
        if (!this.total_of_gm_visited_stats.hasOwnProperty('physical_visit_done') && element['physical_visit_done'] != undefined) {
          this.total_of_gm_visited_stats['physical_visit_done'] = element['physical_visit_done']
        }
        else if (element['physical_visit_done'] != undefined) {
          this.total_of_gm_visited_stats['physical_visit_done'] += element['physical_visit_done']
        }
        if (!this.total_of_gm_visited_stats.hasOwnProperty('total_attended') && element['total_attended'] != undefined) {
          this.total_of_gm_visited_stats['total_attended'] = element['total_attended']
        }
        else if (element['total_attended'] != undefined) {
          this.total_of_gm_visited_stats['total_attended'] += element['total_attended']
        }
      });
      console.log("visited totals", this.total_of_gm_visited_stats);
    },
    getVisitScheduleTabulatorDeatils(data, val, stats, page) {
      this.gm_visit_scheduled = false;
      this.gm_visited_status = false;
      this.selected_filters = '';
      console.log(data, val, stats);
      let selected_values = {
        "data" : data,
        "val" : val,
        "stats" : stats
      }
      this.selected_filters = selected_values
      this.visit_stats_table_details = [];
      var enrollment_data_from_date = null;
      var enrollment_data_to_date = null;
      if (
        this.entrollmentDateRange.startDate !== null &&
        this.entrollmentDateRange.endDate !== null
      ) {
        enrollment_data_from_date = this.customDateFormatter(this.entrollmentDateRange.startDate);
        enrollment_data_to_date = this.customDateFormatter(this.entrollmentDateRange.endDate);
      }
      let obj = {
        start_date: enrollment_data_from_date,
        end_date: enrollment_data_to_date,
        type: val,
        user_id: data.gm_id,
        spoc_id: data.spoc_id,
        m_level: this.selectedLevels.join(),
        u_level: this.selectedMAccLevels.join(),
        team: this.teamData.join(),
        identity: this.identity,
        mobile: this.candidate_number,
        email: this.email,
      }
      if (this.selectedSourceData != null) {
         obj['source'] = this.selectedSourceData.toString();
      }
      this.$vs.loading();
      axios.get(`${constants.MILES_CRM_DEV}visitScheduledStatsDetails?page=${page}`, { params: obj, headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }, })
        .then((response) => {
          console.log("visitScheduledStatsDetails", response.data);
          this.visit_stats_table_details = response.data.data;
          this.tablelinks = response.data.last_page;
          this.totalCount = response.data.total;
          this.currentTablePage = response.data.current_page;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.handleError(error);
        });
    },
    getVisitedStatsTabulatorDeatils(data, val, stats, page) {
      this.gm_visit_scheduled = false;
      this.gm_visited_status = false;
      this.selected_filters = '';
      console.log(data, val, stats);
      let selected_values = {
        "data" : data,
        "val" : val,
        "stats" : stats
      }
      this.selected_filters = selected_values
      this.visit_stats_table_details = [];
      var enrollment_data_from_date = null;
      var enrollment_data_to_date = null;
      if (
        this.entrollmentDateRange.startDate !== null &&
        this.entrollmentDateRange.endDate !== null
      ) {
        enrollment_data_from_date = this.customDateFormatter(this.entrollmentDateRange.startDate);
        enrollment_data_to_date = this.customDateFormatter(this.entrollmentDateRange.endDate);
      }
      let obj = {
        start_date: enrollment_data_from_date,
        end_date: enrollment_data_to_date,
        type: val,
        user_id: data.gm_id,
        spoc_id: data.spoc_id,
        m_level: this.selectedLevels.join(),
        u_level: this.selectedMAccLevels.join(),
        team: this.teamData.join(),
        visit_type: this.visitorStatusData.join(),
        visit_status: this.visitorTypeData.join(),
        identity: this.identity,
        mobile: this.candidate_number,
        email: this.email,
      }
      if (this.selectedSourceData != null) {
         obj['source'] = this.selectedSourceData.toString();
      }
      this.$vs.loading();
      axios.get(`${constants.MILES_CRM_DEV}visitedStatsDetails?page=${page}`, { params: obj, headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }, })
        .then((response) => {
          console.log("visitedStatsDetails", response.data);
          this.visit_stats_table_details = response.data.data;
          this.tablelinks = response.data.last_page;
          this.totalCount = response.data.total;
          this.currentTablePage = response.data.current_page;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.handleError(error);
        });
    },
    getGmData(row_data, stats) {
      console.log(row_data, stats);
      this.gm_clicked_data = row_data;
      this.clicked_stats = stats;
      if (stats == 'visit_scheduled_stats_gm') {
        this.gm_visit_scheduled = true;
        this.gm_visited_status = false;
        this.gm_name = row_data.gm_name;
        this.visit_stats_table_details = [];

        var enrollment_data_from_date = null;
        var enrollment_data_to_date = null;
        if (
          this.entrollmentDateRange.startDate !== null &&
          this.entrollmentDateRange.endDate !== null
        ) {
          enrollment_data_from_date = this.customDateFormatter(this.entrollmentDateRange.startDate);
          enrollment_data_to_date = this.customDateFormatter(this.entrollmentDateRange.endDate);
        }
        let obj = {
          start_date: enrollment_data_from_date,
          end_date: enrollment_data_to_date,
          gm_id: row_data.gm_id,
          spoc_id: row_data.spoc_id,
          m_level: this.selectedLevels.join(),
          u_level: this.selectedMAccLevels.join(),
          team: this.teamData.join(),
          identity: this.identity,
          mobile: this.candidate_number,
          email: this.email,
        }
        if (this.selectedSourceData != null) {
         obj['source'] = this.selectedSourceData.toString();
        }
        this.gm_visit_scheduled_stats_data = [];
        this.$vs.loading();
        axios.get(`${constants.MILES_CRM_DEV}GMSpocsVisitScheduledStats`, { params: obj, headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }, })
          .then((response) => {
            console.log("GMSpocsVisitScheduledStats", response.data);
            this.gm_visit_scheduled_stats_data = response.data
            this.getGmScheduledVisitStatsTotals(this.gm_visit_scheduled_stats_data);
            this.$vs.loading.close();
          })
          .catch((error) => {
            this.$vs.loading.close();
            this.handleError(error);
          });
      } else {
        this.gm_visit_scheduled = false;
      }
      if (stats == 'visited_stats_gm') {
        this.gm_visit_scheduled = false;
        this.gm_visited_status = true;
        this.gm_name = row_data.gm_name;
        this.visit_stats_table_details = [];

        var from_date = null;
        var to_date = null;
        if (
          this.entrollmentDateRange.startDate !== null &&
          this.entrollmentDateRange.endDate !== null
        ) {
          from_date = this.customDateFormatter(this.entrollmentDateRange.startDate);
          to_date = this.customDateFormatter(this.entrollmentDateRange.endDate);
        }
        let obj = {
          start_date: from_date,
          end_date: to_date,
          gm_id: row_data.gm_id,
          spoc_id: row_data.spoc_id,
          m_level: this.selectedLevels.join(),
          u_level: this.selectedMAccLevels.join(),
          team: this.teamData.join(),
          visit_type: this.visitorStatusData.join(),
          visit_status: this.visitorTypeData.join(),
          identity: this.identity,
          mobile: this.candidate_number,
          email: this.email,
        }
        if (this.selectedSourceData != null) {
         obj['source'] = this.selectedSourceData.toString();
        }
        this.gm_visited_stats_data = [];
        this.$vs.loading();
        axios.get(`${constants.MILES_CRM_DEV}GMSpocsVisitedStats`, { params: obj, headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }, })
          .then((response) => {
            console.log("GMSpocsVisitedStats", response.data);
            this.gm_visited_stats_data = response.data
            this.getGmVisitedStatsTotals(this.gm_visited_stats_data);
            this.$vs.loading.close();
          })
          .catch((error) => {
            this.$vs.loading.close();
            this.handleError(error);
          });
      } else {
        this.gm_visited_status = false;
      }
    },

    getTabulatorDeatilsForGmVisitSchedules(data, val, stats, page) {
      if(val != 'yet_to_call_transfer'){
      this.selected_filters = '';
      console.log(data.spoc_id, val, stats);
      let selected_values = {
        "data" : data,
        "val" : val,
        "stats" : stats
      }
      this.selected_filters = selected_values
      this.visit_stats_table_details = [];
      var enrollment_data_from_date = null;
      var enrollment_data_to_date = null;
      if (
        this.entrollmentDateRange.startDate !== null &&
        this.entrollmentDateRange.endDate !== null
      ) {
        enrollment_data_from_date = this.customDateFormatter(this.entrollmentDateRange.startDate);
        enrollment_data_to_date = this.customDateFormatter(this.entrollmentDateRange.endDate);
      }
      let obj = {
        start_date: enrollment_data_from_date,
        end_date: enrollment_data_to_date,
        type: val,
        spoc_id: data.spoc_id,
        m_level: this.selectedLevels.join(),
        u_level: this.selectedMAccLevels.join(),
        team: this.teamData.join(),
        identity: this.identity,
        mobile: this.candidate_number,
        email: this.email,
      }
      if (this.selectedSourceData != null) {
         obj['source'] = this.selectedSourceData.toString();
      }
      this.$vs.loading();
      axios.get(`${constants.MILES_CRM_DEV}GMSpocsVisitScheduledStatsDetails?page=${page}`, { params: obj, headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }, })
        .then((response) => {
          console.log("GMSpocsVisitScheduledStatsDetails", response.data);
          this.visit_stats_table_details = response.data.data;
          this.tablelinks = response.data.last_page;
          this.totalCount = response.data.total;
          this.currentTablePage = response.data.current_page;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.handleError(error);
        });
    
      }
    },

    getTabulatorDeatilsForGmVisitedStats(data, val, stats, page) {
      this.selected_filters = '';
      console.log(data.spoc_id, val, stats);
      let selected_values = {
        "data" : data,
        "val" : val,
        "stats" : stats
      }
      this.selected_filters = selected_values
      this.visit_stats_table_details = [];
      var enrollment_data_from_date = null;
      var enrollment_data_to_date = null;
      if (
        this.entrollmentDateRange.startDate !== null &&
        this.entrollmentDateRange.endDate !== null
      ) {
        enrollment_data_from_date = this.customDateFormatter(this.entrollmentDateRange.startDate);
        enrollment_data_to_date = this.customDateFormatter(this.entrollmentDateRange.endDate);
      }
      let obj = {
        start_date: enrollment_data_from_date,
        end_date: enrollment_data_to_date,
        type: val,
        spoc_id: data.spoc_id,
        m_level: this.selectedLevels.join(),
        u_level: this.selectedMAccLevels.join(),
        team: this.teamData.join(),
        visit_type: this.visitorStatusData.join(),
        visit_status: this.visitorTypeData.join(),
        identity: this.identity,
        mobile: this.candidate_number,
        email: this.email,
      }
      if (this.selectedSourceData != null) {
         obj['source'] = this.selectedSourceData.toString();
      }
      this.$vs.loading();
      axios.get(`${constants.MILES_CRM_DEV}GMSpocsVisitedStatsDetails?page=${page}`, { params: obj, headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }, })
        .then((response) => {
          console.log("GMSpocsVisitedStatsDetails", response.data);
          this.visit_stats_table_details = response.data.data;
          this.tablelinks = response.data.last_page;
          this.totalCount = response.data.total;
          this.currentTablePage = response.data.current_page;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.handleError(error);
        });
    },
    customDateFormatter(date) {
      return moment(date, 'YYYY-MM-DD').format("DD-MM-YYYY");
    },
    refreshVisitorData() {
      this.nextCallDateRange = {
        startDate: null,
        endDate: null,
      }
      this.lastCallDateateRange = {
        startDate: null,
        endDate: null,
      }
      this.dates = null;
      this.enrollment_dates = null;
      this.identity = "";
      this.email = "";
      this.candidate_number = "";
      this.company = "";
      this.candidate_name = "";
      this.selectedLevels = [];
      this.initSelectedSource = null;
      this.initSelectedVisitStatus = [];
      this.initSelectedVisitType = [];
      this.selectedSourceData = null;
      this.visitorStatusData = [];
      this.visitorTypeData = [];
      this.entrollmentDateRange = {
        startDate: new Date(),
        endDate: new Date(),
      };
      if (this.logged_in_user.team != 'CM' && this.logged_in_user.team != 'GM') {
        this.initSelectedCity = [];
        this.teamData = [];
        this.teamData.push('CM');
        this.initSelectedCC = [];
        this.initSelectedSpoc = [];
        this.initSelectedSC = [];
        this.cityData = [];
        this.selectedCCData = [];
        this.selectedSpocData = [];
        this.initSelectedLevels = [];
        this.init_selected_iiml_levels = [];
        this.selectedMAccLevels = [];
      }
      this.getVisitStatsData(1);
      this.gm_visit_scheduled = false;
      this.gm_visited_status = false;
    },


    removechip(chip, name) {
      if (name == "city") {
        if(localStorage.getItem('team') != 'CM' && localStorage.getItem('team') != 'SR'){
        if (this.initSelectedCity.indexOf(chip) === -1) {
          this.initSelectedCity = [];
          this.treeDataCity[0].children.forEach((child) => {
            if (child.label !== chip) {
              this.initSelectedCity.push(child.label);
              this.cityData.push(child.label);
            }
          });
        } else {
          this.initSelectedCity.splice(this.initSelectedCity.indexOf(chip), 1);
          this.cityData.splice(this.cityData.indexOf(chip), 1);
        }
        }
      } else if (name == "cc") {
        if(localStorage.getItem('team') != 'CM' && localStorage.getItem('team') != 'SR'){
        if (this.initSelectedCC.indexOf(chip) === -1) {
          this.initSelectedCC = [];
          this.treeDataCC[0].children.forEach((child) => {
            if (child.label !== chip) {
              this.initSelectedCC.push(child.label);
              this.selectedCCData.push(child.label);
            }
          });
        } else {
          this.initSelectedCC.splice(this.initSelectedCC.indexOf(chip), 1);
          this.selectedCCData.splice(this.selectedCCData.indexOf(chip), 1);
        }
        }
      }
      else if (name == "spoc") {
        if(localStorage.getItem('team') != 'CM' && localStorage.getItem('team') != 'SR'){
        if (this.initSelectedSpoc.indexOf(chip) === -1) {
          this.initSelectedSpoc = [];
          this.treeDataSpoc[0].children.forEach((child) => {
            if (child.label !== chip) {
              this.initSelectedSpoc.push(child.label);
              this.selectedSpocData.push(child.label);
            }
          });
        } else {
          this.initSelectedSpoc.splice(this.initSelectedSpoc.indexOf(chip), 1);
          this.selectedSpocData.splice(this.selectedSpocData.indexOf(chip), 1);
        }
        }
      } else if (name == "sc") {
        if (this.initSelectedSC.indexOf(chip) === -1) {
          this.initSelectedSC = [];
          this.treeDataSC[0].children.forEach((child) => {
            if (child.label !== chip) {
              this.initSelectedSC.push(child.label);
              this.selectedSCData.push(child.label);
            }
          });
        } else {
          this.initSelectedSC.splice(this.initSelectedSC.indexOf(chip), 1);
          this.selectedSCData.splice(this.selectedSCData.indexOf(chip), 1);
        }
      } else if (name == "source") {
        this.selectedSourceData = null
        this.initSelectedSource = null
      } else if (name == "visitorStatus") {
        if (this.initSelectedVisitStatus.indexOf(chip) === -1) {
          this.initSelectedVisitStatus = [];
          this.treeDataVisitorStatus[0].children.forEach((child) => {
            if (child.label !== chip) {
              this.initSelectedVisitStatus.push(child.label);
              this.visitorStatusData.push(child.label);
            }
          });
        } else {
          this.initSelectedVisitStatus.splice(this.initSelectedVisitStatus.indexOf(chip), 1);
          this.visitorStatusData.splice(this.visitorStatusData.indexOf(chip), 1);
        }
      } else if (name == "visitorType") {
        if (this.initSelectedVisitType.indexOf(chip) === -1) {
          this.initSelectedVisitType = [];
          this.treeDataVisitorType[0].children.forEach((child) => {
            if (child.label !== chip) {
              this.initSelectedVisitType.push(child.label);
              this.visitorTypeData.push(child.id);
            }
          });
        } else {
          this.initSelectedVisitType.splice(this.initSelectedVisitType.indexOf(chip), 1);
          this.visitorTypeData.splice(this.visitorTypeData.indexOf(chip), 1);
        }
      } else if (name == "level") {
        if (this.initSelectedLevels.indexOf("All") == 0) {
          this.initSelectedLevels = [];
          this.treeDataLevels[0].children.forEach((child) => {
            child.children.forEach((chi) => {
              if (chi.label !== chip) {
                this.initSelectedLevels.push(chi.label);
                this.selectedLevels.push(chi.label);
              }
            });
          });
        }
        else if (this.initSelectedLevels.indexOf(chip) !== -1) {
          this.initSelectedLevels.splice(this.initSelectedLevels.indexOf(chip), 1);
          this.selectedLevels.splice(this.initSelectedLevels.indexOf(chip), 1);
        } else {
          let childProperty = "";
          this.treeDataLevels[0].children.forEach((child) => {
            if ("children" in child) {
              child.children.forEach((chi) => {
                if (chi.label === chip) {
                  childProperty = child.label;
                }
              });
            }
          });
          this.initSelectedLevels.splice(
            this.initSelectedLevels.indexOf(childProperty),
            1
          );
          this.selectedLevels.splice(
            this.initSelectedLevels.indexOf(childProperty),
            1);
          this.treeDataLevels[0].children.forEach((child) => {
            if (child.label === childProperty) {
              if ("children" in child) {
                child.children.forEach((chi) => {
                  if (chi.label !== chip) {
                    this.initSelectedLevels.push(chi.label);
                    this.selectedLevels.push(chi.label);
                  }
                });
              }
            }
          });
        }
      }
      else if (name == 'iiml_level') {
        this.selectedMAccLevels = [];
        if (this.init_selected_iiml_levels.indexOf(chip) === -1) {
          this.init_selected_iiml_levels = [];
          this.macc_levels[0].children.forEach((child) => {
            if (child.label !== chip) {
              this.init_selected_iiml_levels.push(child.label);
              this.selectedMAccLevels.push(child.label);
            }
          });
        } else {
          this.init_selected_iiml_levels.splice(this.init_selected_iiml_levels.indexOf(chip), 1);
          this.selectedMAccLevels.splice(
            this.selectedMAccLevels.indexOf(chip),
            1
          );
        }
      }
    },
    daterageclear() {
      this.dateRange.startDate = "";
      this.dateRange.endDate = "";
    },

    datatoTimestamp(stringyDate) {
      var dateyDate = new Date(this.timetoTimestamp(stringyDate));
      var ms = dateyDate.valueOf();
      return ms;
    },
    timetoTimestamp(incoming) {
      return new Date(incoming).setHours(0, 0, 0, 0) / 1000;
    },


    mergeAllSearch() {
      this.searchCriteria = [];
      this.searchCriteria.push("divider");
      this.initSelectedBDE.forEach((sort) => {
        if (sort === "All") {
          this.treeDataBDE[0].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
        } else {
          this.searchCriteria.push(sort);
        }
      });
      this.selectedLevels = [];
      this.initSelectedLevels.forEach((sort) => {
        if (sort === "All") {
          this.treeDataLevels[0].children[0].children.forEach((child) => {
            this.selectedLevels.push(child.label);
          });
          this.treeDataLevels[0].children[1].children.forEach((child) => {
            this.selectedLevels.push(child.label);
          });
          this.treeDataLevels[0].children[2].children.forEach((child) => {
            this.selectedLevels.push(child.label);
          });
          this.treeDataLevels[0].children[3].children.forEach((child) => {
            this.selectedLevels.push(child.label);
          });
          this.treeDataLevels[0].children[4].children.forEach((child) => {
            this.selectedLevels.push(child.label);
          });
        } else if (sort === "P1") {
          this.treeDataLevels[0].children[0].children.forEach((child) => {
            this.selectedLevels.push(child.label);
          });
        } else if (sort === "P2") {
          this.treeDataLevels[0].children[1].children.forEach((child) => {
            this.selectedLevels.push(child.label);
          });
        } else if (sort === "P3") {
          this.treeDataLevels[0].children[2].children.forEach((child) => {
            this.selectedLevels.push(child.label);
          });
        } else if (sort === "ENROLLED") {
          this.treeDataLevels[0].children[3].children.forEach((child) => {
            this.selectedLevels.push(child.label);
          });
        } else if (sort === "Dnd") {
          this.treeDataLevels[0].children[4].children.forEach((child) => {
            this.selectedLevels.push(child.label);
          });
        } else {
          this.selectedLevels.push(sort);
        }
      });
      this.selectedMAccLevels = [];
      this.init_selected_iiml_levels.forEach((sort) => {
        if (sort === "All") {
          this.macc_levels[0].children.forEach((child) => {
            this.selectedMAccLevels.push(child.label);
          });
        } else {
          this.selectedMAccLevels.push(sort);
        }
      });
      this.searchCriteria.push("divider");
      if (this.initSelected !== "") {
        this.searchCriteria.push(this.initSelected);
      }
      this.searchCriteria.push("divider");
      this.infosearch.forEach((info) => {
        this.searchCriteria.push(info);
      });

      if (this.logged_in_user.team == 'CM') {
        this.teamData = [];
        this.teamData = this.treeDataTeam;
      }
      this.cityData = [];
      this.initSelectedCity.forEach((sort) => {
        if (sort === "All") {
          this.treeDataCity[0].children.forEach((child) => {
            // this.searchCriteria.push(child.label);
            this.cityData.push(child.label);
          });
        } else {
          // this.searchCriteria.push(sort);
          this.cityData.push(sort);
        }
      });
      this.selectedCCData = [];
      this.initSelectedCC.forEach((sort) => {
        if (sort === "All") {
          this.treeDataCC[0].children.forEach((child) => {
            // this.searchCriteria.push(child.label);
            this.selectedCCData.push(child.label);
          });
        } else {
          // this.searchCriteria.push(sort);
          this.selectedCCData.push(sort);
        }
      });
      this.selectedSpocData = [];
      this.initSelectedSpoc.forEach((sort) => {
        if (sort === "All") {
          this.treeDataSpoc[0].children.forEach((child) => {
            // this.searchCriteria.push(child.label);
            this.selectedSpocData.push(child.label);
          });
        } else {
          // this.searchCriteria.push(sort);
          this.selectedSpocData.push(sort);
        }
      });
      this.selectedSCData = [];
      this.initSelectedSC.forEach((sort) => {
        if (sort === "All") {
          this.treeDataSC[0].children.forEach((child) => {
            this.selectedSCData.push(child.label);
          });
        } else {
          // this.searchCriteria.push(sort);
          this.selectedSCData.push(sort);
        }
      });
      this.selectedSourceData = null;
      this.selectedSourceData = this.initSelectedSource;
      this.visitorStatusData = [];
      this.initSelectedVisitStatus.forEach((sort) => {
        if (sort === "All") {
          this.treeDataVisitorStatus[0].children.forEach((child) => {
            this.visitorStatusData.push(child.label)
          })
        } else {
          this.visitorStatusData.push(sort)
        }
      });
      this.visitorTypeData = [];
      this.initSelectedVisitType.forEach((sort) => {
        if (sort === "All") {
          this.treeDataVisitorType[0].children.forEach((child) => {
            this.visitorTypeData.push(child.id)
          })
        } else {
          this.visitorTypeData.push(sort)
        }
      });
    },
    clear() {
      this.value1 = "";
      this.value2 = "";
    },
    showTreeBox() {
      console.log("clicked");
      return this.treeDataBDE;
    },
    generateKey(item) {
      return `${item}-${Math.random()}`;
    },

  
  },
};
</script>
  
<style lang="scss">
.vs--searchable .vs__dropdown-toggle {
  height: 38px;
}

.headr {
  color: gray;
  font-size: 25px;
  margin-bottom: 2%;
}

.gap {
  margin-bottom: 4%;
}

.inicolor {
  color: gray;
}

.cardtext {
  color: gray;
  font-size: 20px;
}

.tree3 {
  // float: left;
  // width: 33%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px dotted #ccccdd;
  overflow: auto;
  height: 500px;
  width: 300px;
}

.vs-dropdown--menu {
  width: 300px;
  // height: 500px;
  // overflow: hidden;
}

.gap {
  margin-right: 1%;
}

.examplex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

i {
  font-size: 18px;
}

.vl {
  border-left: 1px solid black;
  height: 35px;
}

.search-container {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  width: 100%;
  min-height: 450.69px;
}

.eligibility_count {
  background: #0044ba;
  border-radius: 15px 0px;
  width: 233.62px;
  height: 37.8px;
  display: flex;
  justify-content: space-between;
  padding-inline: 1%;
  align-items: center;
}

.eligibility_text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
}

.visit-options_filter {
  min-height: 414.41px;
  margin: 2px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.visit-options_filter .vx-card__collapsible-content .vx-card__body {
  padding: 5px 20px !important;
}

.filter-heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}

.none_padding {
  padding: 0px;
}

.visitor-tree-drop#citys>.vs-con-dropdown {
  background: #eae5fa;
  border: 1px solid rgba(46, 13, 146, 0.5);
  width: 90%;
  border-radius: 10px;
  margin-inline: 2%;
}

.visitor-tree-drop#team>.vs-con-dropdown {
  background: #caecc5;
  border: 1px solid #96e08b;
  width: 90%;
  border-radius: 10px;
  margin-inline: 2%;
}

.visitor-tree-drop#cc>.vs-con-dropdown {
  background: #fae1fe;
  border: 1px solid #de56f4;
  border-radius: 10px;
  width: 90%;
  margin-inline: 2%;
}

.visitor-tree-drop#spoc>.vs-con-dropdown {
  background: rgba(188, 235, 250, 0.69);
  border: 1px solid #1fabd8;
  border-radius: 10px;
  width: 90%;
  margin-inline: 2%;
}

.visitor-tree-drop#sc>.vs-con-dropdown {
  background: #b4edff;
  border: 1px solid #13a0cd;
  border-radius: 10px;
  width: 90%;
  margin-inline: 2%;
}

.visitor-tree-drop#source>.vs-con-dropdown {
  background: rgba(255, 194, 194, 0.69);
  border: 1px solid #ec6868;
  border-radius: 10px;
  width: 90%;
  margin-inline: 2%;
}

.visitor-tree-drop#Visitor>.vs-con-dropdown {
  background: rgba(255, 194, 194, 0.69);
  border: 1px solid #ec6868;
  border-radius: 10px;
  width: 100%;
  line-height: 35px;
}

.visitor-tree-drop#level>.vs-con-dropdown {
  background: rgba(255, 194, 194, 0.69);
  border: 1px solid #ec6868;
  border-radius: 10px;
  width: 100%;
  line-height: 35px;
}

.visitor-tree-drop#status>.vs-con-dropdown {
  background: #F5E1CE;
  border: 1px solid #F1A964;
  border-radius: 10px;
  width: 100%;
}

.visitor-tree-drop#type>.vs-con-dropdown {
  background: #f1ad78;
  border: 1px solid #F38630;
  border-radius: 10px;
  width: 100%;
}

.visitor-tree-drop#level_drop>.vs-con-dropdown {
  background: #99eee3;
  border: 1px solid #14d685;
  border-radius: 10px;
  width: 100%;
}

.visitor-tree-drop#u_level_drop>.vs-con-dropdown {
  background: #edebf3;
  border: 1px solid #3b1c98;
  border-radius: 10px;
  width: 100%;
}

.visitor-tree-drop>.vs-con-dropdown {
  width: 100%;
  min-height: 32px;
  border-radius: 10px;
  // padding: 5.8px;
  padding-inline: 5px;
  // padding-top:3px;
  color: #000000;
  display: flex !important;
  align-items: center;
}

.ele-inside-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  padding-inline-start: 10px;
}

.ele-inside-text>i {
  color: rgba(0, 0, 0, 0.4);
}

.a-iconns>.material-icons {
  margin-left: auto;
}

i {
  font-size: 18px;
}

.a-iconns {
  color: #000;
  font-weight: 500;
  font-size: 14px;
  width: 99%;
  display: flex;
  justify-content: flex-end !important;
}

.visitor_dropdown .vs__dropdown-toggle {
  height: 45px !important;
}

.visitor_dropdown .vs__dropdown-toggle .vs__selected-options span {
  font-weight: 500;
  font-size: 14px;
  font-family: "Montserrat";
  font-style: normal;
  color: #000000;
}

.visitor>.reportrange-text {
  background: #ffffff;
  border: 1px solid #0044ba;
  box-shadow: 0px 4px 5px rgb(0 0 0 / 16%);
  min-height: 44.47px;
  border-radius: 10px;
}

.date_filter_heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

.visit_date_picker>div input {
  background: #ffffff;
  -webkit-padding-start: 9%;
  display: block;
  border-radius: 5px;
  min-height: 44.47px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  width: 100%;
  border: 1px solid #0044ba;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.16);
  color: #000000 !important;
  border-radius: 10px;
}

.visitor_search_criteria {
  width: 100%;
  background: #ffffff;
  min-height: 165px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}

.Criteria_heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #000000;
}

#stats_total {
  color: #0044BA;
}

.visit-option-side {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.criteria_chip {
  border-radius: 5px;
}

.criteria_chip .vs-chip--close {
  background: transparent !important;
  color: #000000;
}

.criteria_chip .vs-chip--text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  text-align: center;
  color: #000000;
}

.visit-input-filter {
  width: 100% !important;
}

.visit-input-filter.vs-con-input .input-span-placeholder {
  font-size: 14px;
  margin-left: 1%;
}

.visit-input-filter>.vs-con-input>.vs-inputx {
  height: 40px;
  border: 1px solid #0044ba !important;
  border-radius: 15px;
}

.no-padding {
  padding: 0px;
}

@media only screen and (max-width: 1450px) {
  .date_filter_heading {
    font-size: 9px
  }
}

.gm-stats-filter-card .vx-card__collapsible-content .vx-card__body {
  padding: 0px !important;
}

.gm-stats-filter-card {
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
}

.desc-border-box2 p {
  font-family: 'Montserrat';
  display: flex;
  justify-content: center;
  padding-inline: 20px;
  align-items: center;
  background-color: #0044ba;
  border-radius: 0 0 15px 15px;
  width: 20%;
  height: 35px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
}

.fixTableHead {
  overflow-y: auto;
  max-height: 350px;
}

.fixTableHead thead th {
  position: sticky;
  top: 0;
}

.rwd-table {
  height: 100%;
  margin: 10px auto;
  background-color: white;
  /* above is decorative or flexible */
  position: relative;
  /* could be absolute or relative */
  padding-top: 30px;
  /* height of header */


  width: 98%;
  font-family: 'Montserrat';
  margin: 25px auto;
  border-collapse: collapse;
  border-radius: 15px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.10),
    0px 10px 20px rgba(0, 0, 0, 0.05),
    0px 20px 20px rgba(0, 0, 0, 0.05),
    0px 30px 20px rgba(0, 0, 0, 0.05);

  tr {
    &:hover {
      background: #f4f4f4;

      td {
        color: #555;
      }
    }
  }

  th,
  td {
    color: #000;
    border-bottom: 1px dotted rgba(0, 0, 0, 0.25);
    border-left: 1px solid rgba(0, 0, 0, 0.25);
    padding: 5px 14px;
    border-collapse: collapse;
  }

  th:first-child,
  td:first-child {
    border-left: 0px solid transparent !important;
  }

  th {
    background: #0044ba;
    color: #fff;
    font-size: 12px;

    &.last {
      border-right: none;
    }
  }

  tr:first-child th:first-child {
    border-top-left-radius: 10px;
  }

  tr:last-child th:last-child {
    border-top-right-radius: 10px;
  }

  tr:first-child td:last-child {
    border-top-right-radius: 10px;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
  }
}

.rwd-table {
  tr td:last-child {
    font-size: 12px;
  }
}
.editTxt {
  font-size: 150%;
}
.assignspocpopupmultiplewidthnet .vs-popup {
  width: 40% !important;
}
</style>