<template>
  <div>
    <vx-card class="tabulator_card">
      <vs-row>
        <vs-col vs-w="12" vs-type="flex" vs-justify="center" class="card2 no-padd mb-8">
          <p style="text-align:center">
            <span v-if="selected_values.stats == 'visit_scheduled_stats'"> Visit Scheduled Stats  - <span v-if="logged_in_user != 'Admin'">{{ selected_values.data.spoc_name }}</span><span v-else>{{ selected_values.data.gm_name }}(GM)</span> - {{ getSelectedStat(selected_values.val) }} &nbsp;</span>
            <span v-else-if="selected_values.stats == 'gm_visit_scheduled_stats'"> Visit Scheduled Stats  - {{ selected_values.data.spoc_name }}(SPOC) - {{ getSelectedStat(selected_values.val) }} &nbsp;</span>
            <span v-else-if="selected_values.stats == 'visited_stats'"> Visited Stats  - <span v-if="logged_in_user != 'Admin'">{{ selected_values.data.spoc_name }}</span><span v-else>{{ selected_values.data.gm_name }}(GM)</span> - {{ getSelectedStat(selected_values.val) }} &nbsp;</span>
            <span v-else-if="selected_values.stats == 'gm_visited_stats'"> Visited Stats  - {{ selected_values.data.spoc_name }}(SPOC) - {{ getSelectedStat(selected_values.val) }} &nbsp;</span>
          </p>
        </vs-col>
        <vs-col vs-w="12" vs-type="flex" vs-justify="center" class="no-padd">
          <vs-table class="gm-table" :data="visit_stats_table_details">
            <template slot="thead">
              <vs-th>Can-ID</vs-th>
              <vs-th>Candidate Name</vs-th>
              <vs-th>U Level</vs-th>
              <vs-th>M Level</vs-th>
              <vs-th>City</vs-th>
              <vs-th>Course</vs-th>
              <vs-th>Comments</vs-th>
              <vs-th>Visit Count</vs-th>
              <vs-th>Visit Status</vs-th>
              <vs-th>Coming From</vs-th>
              <vs-th>SPOC</vs-th>
              <vs-th v-if="selected_values.stats == 'visit_scheduled_stats' || selected_values.stats == 'gm_visit_scheduled_stats'">Scheduled Date</vs-th>
              <vs-th v-else-if="selected_values.stats == 'visited_stats' || selected_values.stats == 'gm_visited_stats'">Visited Scheduled Date</vs-th>
              <vs-th>Action</vs-th>
            </template>

            <template slot-scope="{ data }">
              <tbody ref="tbody">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>
                    <div>{{ tr.can_id }}</div>
                  </vs-td>
                  <vs-td>
                    <div>{{ tr.name }}</div>
                  </vs-td>
                  <vs-td>
                    <div>{{ tr.ulevel }}</div>
                  </vs-td>
                  <vs-td>
                    <div>{{ tr.mlevel }}</div>
                  </vs-td>
                  <vs-td>
                    <div>{{ tr.city }}</div>
                  </vs-td>
                  <vs-td>
                    <div>{{ tr.courses }}</div>
                  </vs-td>
                  <vs-td>
                    <div>{{ tr.comments }}</div>
                  </vs-td>
                  <vs-td>
                    <div>{{ tr.visit_count }}</div>
                  </vs-td>
                  <vs-td>
                    <div>{{ tr.visit_status }}</div>
                  </vs-td>
                  <vs-td>
                    <div>{{ tr.coming_from }}</div>
                  </vs-td>
                  <vs-td>
                    <div>{{ tr.spoc }}</div>
                  </vs-td>
                  <vs-td>
                    <div v-if="tr.date != null">{{ getFormattedDate(tr.date) }}</div>
                  </vs-td>
                  <vs-td>
                    <v-row>
                      <div v-if="!tr.ne_data">
                        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="8" style="margin-left: -10%;">
                          <div @click="openBigPopupEmit(tr)">
                            <vs-icon icon="info_outline" size="small" color="dark"></vs-icon>
                          </div>
                        </vs-col>
                      </div>
                      <div v-else>
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2.5"
                          style="margin-left: -10%;">
                          <div @click="calluntracked(tr)">
                            <vs-icon icon="call" size="small" color="dark"></vs-icon>
                          </div>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
                          <div @click="openPopupEmit(tr)">
                            <vs-icon icon="edit" size="small" color="dark"></vs-icon>
                          </div>
                        </vs-col>
                      </div>
                    </v-row>
                  </vs-td>
                </vs-tr>
              </tbody>
            </template>
          </vs-table>
        </vs-col>
      </vs-row>
    </vx-card>

  </div>
</template>
<script>
import axios from "axios";
import constants from "../../constants.json";
import EventBus from "../components/eventbus";
import moment from "moment";
export default {
  props: ["visit_stats_table_details", "selected_values", "logged_in_user"],
  data() {
    return {
    }
  },
  mounted() {
    console.log("selected details", this.selected_values);
  },
  methods: {
    openBigPopupEmit(payload) {
      this.openBigPopup(payload.mwb_id);
    },
    openPopupEmit(ne_data) {
      console.log(ne_data);
      let obj = ne_data
      let { person_name: name, phone: mobile, ...rest } = obj;
      obj = { name, mobile, ...rest }
      obj["course"] = "MAcc";
      obj["net_enquiry_type"] = "Webinar-WR";
      EventBus.$emit("open-netEnquiries-details-popup", obj);
    },
    async calluntracked(data) {
      console.log(data);
      let obj = data
      let { person_name: name, phone: mobile, ...rest } = obj;
      obj = { name, mobile, ...rest }
      obj["course"] = "MAcc";
      obj["net_enquiry_type"] = "Webinar-WR";
      let response = await this.checkPermission();
      if (localStorage.getItem("role") !== "cm4") {
        if (
          this.$route.path !== "/queue" &&
          this.$route.path !== "/placementDrive" &&
          this.$route.path !== "/visitStatus" &&
          this.$route.path !== "/logout" &&
          this.$route.path !== "/UtilizationReport" &&
          this.$route.path !== "/receipts"
        ) {
          if (response === "no") {
            this.$vs.notify({
              title: "Permission denied",
              text: "Please contact admin!!!",
              color: "danger",
              icon: "error",
            });
            return;
          }
        }
      }
      let params = {};
      params.phone_number = data.phone;
      params.email = data.email;
      axios
        .get(constants.SERVER_API + "clickToCallUntracked", {
          params: params,
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log(response);
          // this.open = false;
          // if (this.userdata.matched !== 1) {
          EventBus.$emit("open-netEnquiries-details-popup", obj);
          // }
        })
        .catch((error) => {
          this.handleError(error);
        });

    },
    getFormattedDate(date) {
      return moment
        .unix(date)
        .format("DD-MMM-YYYY");
    },
    getSelectedStat(val) {
            console.log('getSelectedStat', val);
            if (val == 'visit_scheduled_by_spoc') {
                return 'Visit Scheduled by SPOC';
            }
            else if (val == 'gmeet_scheduled_by_spoc') {
                return 'G-Meet Scheduled by SPOC';
            }
            else if (val == 'scheduled_by_clevertap') {
                return 'Scheduled by System';
            }
            else if (val == 'total_scheduled') {
                return 'Total Scheduled';
            }
            else if (val == 'called') {
                return 'Called';
            }
            else if (val == 'yet_to_call') {
                return 'Yet To call';
            }
            else if (val == 'gmeet_done') {
                return 'G-Meet Done';
            }
            else if (val == 'physical_visit_done') {
                return 'Physical Visit Done';
            }
            else if (val == 'total_attended') {
                return 'Total Attended';
            }
    },
  }
    
  };
</script>
<style>
/* .gm-table {
  border-radius: none;
  border: 1px solid rgba(0, 0, 0, .25) !important;
} */

.tabulator_card {
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
}

.card2 p {
  font-family: 'Montserrat';
  display: flex;
  justify-content: center;
  padding-inline: 20px;
  align-items: center;
  background-color: #6d7ae0;
  border-radius: 0 0 15px 15px;
  width: 52%;
  height: 35px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
}

.vx-card .vx-card__collapsible-content .vx-card__body {
  padding-top: 0;
}</style>